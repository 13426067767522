import { IItemToSell } from 'app/shared/model/item-to-sell.model';
import { PaymentType } from 'app/shared/model/enumerations/payment-type.model';
import { StatusType } from 'app/shared/model/enumerations/status-type.model';

export interface ISale {
  id?: number;
  totalPayment?: number | null;
  paymentType?: keyof typeof PaymentType | null;
  status?: keyof typeof StatusType | null;
  itemToSells?: IItemToSell[] | null;
}

export const defaultValue: Readonly<ISale> = {};
