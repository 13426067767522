import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities, reset } from './environment.reducer';

export const Environment = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [sorting, setSorting] = useState(false);

  const environmentList = useAppSelector(state => state.environment.entities);
  const loading = useAppSelector(state => state.environment.loading);
  const links = useAppSelector(state => state.environment.links);
  const updateSuccess = useAppSelector(state => state.environment.updateSuccess);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setSorting(true);
  };

  const handleSyncList = () => {
    resetAll();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="environment-heading" data-cy="EnvironmentHeading">
        <Translate contentKey="surrealManagerApp.environment.home.title">Environments</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="surrealManagerApp.environment.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/environment/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="surrealManagerApp.environment.home.createLabel">Create new Environment</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          dataLength={environmentList ? environmentList.length : 0}
          next={handleLoadMore}
          hasMore={paginationState.activePage - 1 < links.next}
          loader={<div className="loader">Loading ...</div>}
        >
          {environmentList && environmentList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="surrealManagerApp.environment.id">ID</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                  </th>
                  <th className="hand" onClick={sort('frontEndName')}>
                    <Translate contentKey="surrealManagerApp.environment.frontEndName">Front End Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('frontEndName')} />
                  </th>
                  <th className="hand" onClick={sort('frontEndLocationURL')}>
                    <Translate contentKey="surrealManagerApp.environment.frontEndLocationURL">Front End Location URL</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('frontEndLocationURL')} />
                  </th>
                  <th className="hand" onClick={sort('apiGatewayName')}>
                    <Translate contentKey="surrealManagerApp.environment.apiGatewayName">Api Gateway Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('apiGatewayName')} />
                  </th>
                  <th className="hand" onClick={sort('apiGatewayURL')}>
                    <Translate contentKey="surrealManagerApp.environment.apiGatewayURL">Api Gateway URL</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('apiGatewayURL')} />
                  </th>
                  <th className="hand" onClick={sort('webSocketURL')}>
                    <Translate contentKey="surrealManagerApp.environment.webSocketURL">Web Socket URL</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('webSocketURL')} />
                  </th>
                  <th className="hand" onClick={sort('webSocketPort')}>
                    <Translate contentKey="surrealManagerApp.environment.webSocketPort">Web Socket Port</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('webSocketPort')} />
                  </th>
                  <th className="hand" onClick={sort('useMatchmaker')}>
                    <Translate contentKey="surrealManagerApp.environment.useMatchmaker">Use Matchmaker</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('useMatchmaker')} />
                  </th>
                  <th className="hand" onClick={sort('matchmakerServerIP')}>
                    <Translate contentKey="surrealManagerApp.environment.matchmakerServerIP">Matchmaker Server IP</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('matchmakerServerIP')} />
                  </th>
                  <th className="hand" onClick={sort('matchmakerServerPort')}>
                    <Translate contentKey="surrealManagerApp.environment.matchmakerServerPort">Matchmaker Server Port</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('matchmakerServerPort')} />
                  </th>
                  <th className="hand" onClick={sort('turnServerURL')}>
                    <Translate contentKey="surrealManagerApp.environment.turnServerURL">Turn Server URL</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('turnServerURL')} />
                  </th>
                  <th className="hand" onClick={sort('turnServerPort')}>
                    <Translate contentKey="surrealManagerApp.environment.turnServerPort">Turn Server Port</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('turnServerPort')} />
                  </th>
                  <th className="hand" onClick={sort('stunServerURL')}>
                    <Translate contentKey="surrealManagerApp.environment.stunServerURL">Stun Server URL</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('stunServerURL')} />
                  </th>
                  <th className="hand" onClick={sort('stunServerPort')}>
                    <Translate contentKey="surrealManagerApp.environment.stunServerPort">Stun Server Port</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('stunServerPort')} />
                  </th>
                  <th>
                    <Translate contentKey="surrealManagerApp.environment.deploys">Deploys</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {environmentList.map((environment, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/environment/${environment.id}`} color="link" size="sm">
                        {environment.id}
                      </Button>
                    </td>
                    <td>{environment.frontEndName}</td>
                    <td>{environment.frontEndLocationURL}</td>
                    <td>{environment.apiGatewayName}</td>
                    <td>{environment.apiGatewayURL}</td>
                    <td>{environment.webSocketURL}</td>
                    <td>{environment.webSocketPort}</td>
                    <td>{environment.useMatchmaker ? 'true' : 'false'}</td>
                    <td>{environment.matchmakerServerIP}</td>
                    <td>{environment.matchmakerServerPort}</td>
                    <td>{environment.turnServerURL}</td>
                    <td>{environment.turnServerPort}</td>
                    <td>{environment.stunServerURL}</td>
                    <td>{environment.stunServerPort}</td>
                    <td>
                      {environment.deploys ? (
                        <Link to={`/surreal-sub-experience/${environment.deploys.id}`}>{environment.deploys.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`/environment/${environment.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`/environment/${environment.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/environment/${environment.id}/delete`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="surrealManagerApp.environment.home.notFound">No Environments found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Environment;
