import { ISurrealUser } from 'app/shared/model/surreal-user.model';
import { IOwner } from 'app/shared/model/owner.model';
import { AuthType } from 'app/shared/model/enumerations/auth-type.model';

export interface IClientOrganization {
  id?: number;
  name?: string | null;
  authType?: keyof typeof AuthType | null;
  surrealUsers?: ISurrealUser[] | null;
  owner?: IOwner | null;
}

export const defaultValue: Readonly<IClientOrganization> = {};
