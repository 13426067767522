import { IDescriptor } from 'app/shared/model/descriptor.model';
import { IAppearance } from 'app/shared/model/appearance.model';
import { IItemToSell } from 'app/shared/model/item-to-sell.model';

export interface IImageFile {
  id?: number;
  name?: string | null;
  fileDataContentType?: string | null;
  fileData?: string | null;
  fileLocationURL?: string | null;
  descriptor?: IDescriptor | null;
  appearance?: IAppearance | null;
  appearane?: IAppearance | null;
  itemToSell?: IItemToSell | null;
}

export const defaultValue: Readonly<IImageFile> = {};
