import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './surreal-main-experience.reducer';

export const SurrealMainExperienceDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const surrealMainExperienceEntity = useAppSelector(state => state.surrealMainExperience.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="surrealMainExperienceDetailsHeading">
          <Translate contentKey="surrealManagerApp.surrealMainExperience.detail.title">SurrealMainExperience</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{surrealMainExperienceEntity.id}</dd>
          <dt>
            <span id="experienceName">
              <Translate contentKey="surrealManagerApp.surrealMainExperience.experienceName">Experience Name</Translate>
            </span>
          </dt>
          <dd>{surrealMainExperienceEntity.experienceName}</dd>
          <dt>
            <span id="frontEndURL">
              <Translate contentKey="surrealManagerApp.surrealMainExperience.frontEndURL">Front End URL</Translate>
            </span>
          </dt>
          <dd>{surrealMainExperienceEntity.frontEndURL}</dd>
          <dt>
            <span id="frontEndPort">
              <Translate contentKey="surrealManagerApp.surrealMainExperience.frontEndPort">Front End Port</Translate>
            </span>
          </dt>
          <dd>{surrealMainExperienceEntity.frontEndPort}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealMainExperience.mapPointList">Map Point List</Translate>
          </dt>
          <dd>{surrealMainExperienceEntity.mapPointList ? surrealMainExperienceEntity.mapPointList.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealMainExperience.environment">Environment</Translate>
          </dt>
          <dd>{surrealMainExperienceEntity.environment ? surrealMainExperienceEntity.environment.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealMainExperience.owner">Owner</Translate>
          </dt>
          <dd>{surrealMainExperienceEntity.owner ? surrealMainExperienceEntity.owner.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/surreal-main-experience" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/surreal-main-experience/${surrealMainExperienceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SurrealMainExperienceDetail;
