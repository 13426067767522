import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SchedulesForExperienceGPUFleet from './schedules-for-experience-gpu-fleet';
import SchedulesForExperienceGPUFleetDetail from './schedules-for-experience-gpu-fleet-detail';
import SchedulesForExperienceGPUFleetUpdate from './schedules-for-experience-gpu-fleet-update';
import SchedulesForExperienceGPUFleetDeleteDialog from './schedules-for-experience-gpu-fleet-delete-dialog';

const SchedulesForExperienceGPUFleetRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SchedulesForExperienceGPUFleet />} />
    <Route path="new" element={<SchedulesForExperienceGPUFleetUpdate />} />
    <Route path=":id">
      <Route index element={<SchedulesForExperienceGPUFleetDetail />} />
      <Route path="edit" element={<SchedulesForExperienceGPUFleetUpdate />} />
      <Route path="delete" element={<SchedulesForExperienceGPUFleetDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SchedulesForExperienceGPUFleetRoutes;
