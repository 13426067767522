import dayjs from 'dayjs';

export interface ISchedulesForExperienceGPUFleet {
  id?: number;
  isActive?: boolean;
  startedAt?: string | null;
  endedAt?: string | null;
  affectedGPUCountInLastRun?: number | null;
}

export const defaultValue: Readonly<ISchedulesForExperienceGPUFleet> = {
  isActive: false,
};
