import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './surreal-sub-experience.reducer';

export const SurrealSubExperienceDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const surrealSubExperienceEntity = useAppSelector(state => state.surrealSubExperience.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="surrealSubExperienceDetailsHeading">
          <Translate contentKey="surrealManagerApp.surrealSubExperience.detail.title">SurrealSubExperience</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{surrealSubExperienceEntity.id}</dd>
          <dt>
            <span id="experienceName">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.experienceName">Experience Name</Translate>
            </span>
          </dt>
          <dd>{surrealSubExperienceEntity.experienceName}</dd>
          <dt>
            <span id="startAt">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.startAt">Start At</Translate>
            </span>
          </dt>
          <dd>
            {surrealSubExperienceEntity.startAt ? (
              <TextFormat value={surrealSubExperienceEntity.startAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endAt">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.endAt">End At</Translate>
            </span>
          </dt>
          <dd>
            {surrealSubExperienceEntity.endAt ? (
              <TextFormat value={surrealSubExperienceEntity.endAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="isRecurring">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.isRecurring">Is Recurring</Translate>
            </span>
          </dt>
          <dd>{surrealSubExperienceEntity.isRecurring ? 'true' : 'false'}</dd>
          <dt>
            <span id="useMatchmaker">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.useMatchmaker">Use Matchmaker</Translate>
            </span>
          </dt>
          <dd>{surrealSubExperienceEntity.useMatchmaker ? 'true' : 'false'}</dd>
          <dt>
            <span id="matchmakerServerIP">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.matchmakerServerIP">Matchmaker Server IP</Translate>
            </span>
          </dt>
          <dd>{surrealSubExperienceEntity.matchmakerServerIP}</dd>
          <dt>
            <span id="matchmakerServerPort">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.matchmakerServerPort">Matchmaker Server Port</Translate>
            </span>
          </dt>
          <dd>{surrealSubExperienceEntity.matchmakerServerPort}</dd>
          <dt>
            <span id="turnServerURL">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.turnServerURL">Turn Server URL</Translate>
            </span>
          </dt>
          <dd>{surrealSubExperienceEntity.turnServerURL}</dd>
          <dt>
            <span id="turnServerPort">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.turnServerPort">Turn Server Port</Translate>
            </span>
          </dt>
          <dd>{surrealSubExperienceEntity.turnServerPort}</dd>
          <dt>
            <span id="stunServerURL">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.stunServerURL">Stun Server URL</Translate>
            </span>
          </dt>
          <dd>{surrealSubExperienceEntity.stunServerURL}</dd>
          <dt>
            <span id="stunServerPort">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.stunServerPort">Stun Server Port</Translate>
            </span>
          </dt>
          <dd>{surrealSubExperienceEntity.stunServerPort}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="surrealManagerApp.surrealSubExperience.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{surrealSubExperienceEntity.isActive ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealSubExperience.eventAppearance">Event Appearance</Translate>
          </dt>
          <dd>{surrealSubExperienceEntity.eventAppearance ? surrealSubExperienceEntity.eventAppearance.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealSubExperience.eventIntegration">Event Integration</Translate>
          </dt>
          <dd>{surrealSubExperienceEntity.eventIntegration ? surrealSubExperienceEntity.eventIntegration.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealSubExperience.descriptor">Descriptor</Translate>
          </dt>
          <dd>{surrealSubExperienceEntity.descriptor ? surrealSubExperienceEntity.descriptor.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealSubExperience.mapPointList">Map Point List</Translate>
          </dt>
          <dd>{surrealSubExperienceEntity.mapPointList ? surrealSubExperienceEntity.mapPointList.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealSubExperience.subExperienceEnvironment">Sub Experience Environment</Translate>
          </dt>
          <dd>{surrealSubExperienceEntity.subExperienceEnvironment ? surrealSubExperienceEntity.subExperienceEnvironment.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealSubExperience.makesUp">Makes Up</Translate>
          </dt>
          <dd>{surrealSubExperienceEntity.makesUp ? surrealSubExperienceEntity.makesUp.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/surreal-sub-experience" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/surreal-sub-experience/${surrealSubExperienceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SurrealSubExperienceDetail;
