export enum ComputeSystem {
  AmazonAWS = 'AmazonAWS',

  GoogleGCP = 'GoogleGCP',

  MicrosoftAzure = 'MicrosoftAzure',

  OracleOCI = 'OracleOCI',

  PrivateCenter = 'PrivateCenter',
}
