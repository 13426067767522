import { IAppointment } from 'app/shared/model/appointment.model';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';

export interface ISurrealLevel {
  id?: number;
  levelName?: string;
  displayName?: string;
  child?: ISurrealLevel | null;
  appointment?: IAppointment | null;
  parent?: ISurrealLevel | null;
  surrealMainExperience?: ISurrealMainExperience | null;
}

export const defaultValue: Readonly<ISurrealLevel> = {};
