import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './cloud-account-secret.reducer';

export const CloudAccountSecretDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const cloudAccountSecretEntity = useAppSelector(state => state.cloudAccountSecret.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="cloudAccountSecretDetailsHeading">
          <Translate contentKey="surrealManagerApp.cloudAccountSecret.detail.title">CloudAccountSecret</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{cloudAccountSecretEntity.id}</dd>
          <dt>
            <span id="accountID">
              <Translate contentKey="surrealManagerApp.cloudAccountSecret.accountID">Account ID</Translate>
            </span>
          </dt>
          <dd>{cloudAccountSecretEntity.accountID}</dd>
          <dt>
            <span id="accountSecret">
              <Translate contentKey="surrealManagerApp.cloudAccountSecret.accountSecret">Account Secret</Translate>
            </span>
          </dt>
          <dd>{cloudAccountSecretEntity.accountSecret}</dd>
          <dt>
            <span id="accountBase64EncodedKey">
              <Translate contentKey="surrealManagerApp.cloudAccountSecret.accountBase64EncodedKey">Account Base 64 Encoded Key</Translate>
            </span>
          </dt>
          <dd>{cloudAccountSecretEntity.accountBase64EncodedKey}</dd>
          <dt>
            <span id="subscriptionID">
              <Translate contentKey="surrealManagerApp.cloudAccountSecret.subscriptionID">Subscription ID</Translate>
            </span>
          </dt>
          <dd>{cloudAccountSecretEntity.subscriptionID}</dd>
        </dl>
        <Button tag={Link} to="/cloud-account-secret" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/cloud-account-secret/${cloudAccountSecretEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CloudAccountSecretDetail;
