import { ComputeSystem } from 'app/shared/model/enumerations/compute-system.model';
import { CloudLocation } from 'app/shared/model/enumerations/cloud-location.model';

export interface IDataCenter {
  id?: number;
  name?: keyof typeof ComputeSystem | null;
  location?: keyof typeof CloudLocation | null;
}

export const defaultValue: Readonly<IDataCenter> = {};
