import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities, reset } from './instance.reducer';

export const Instance = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [sorting, setSorting] = useState(false);

  const instanceList = useAppSelector(state => state.instance.entities);
  const loading = useAppSelector(state => state.instance.loading);
  const links = useAppSelector(state => state.instance.links);
  const updateSuccess = useAppSelector(state => state.instance.updateSuccess);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setSorting(true);
  };

  const handleSyncList = () => {
    resetAll();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="instance-heading" data-cy="InstanceHeading">
        <Translate contentKey="surrealManagerApp.instance.home.title">Instances</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="surrealManagerApp.instance.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/instance/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="surrealManagerApp.instance.home.createLabel">Create new Instance</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          dataLength={instanceList ? instanceList.length : 0}
          next={handleLoadMore}
          hasMore={paginationState.activePage - 1 < links.next}
          loader={<div className="loader">Loading ...</div>}
        >
          {instanceList && instanceList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="surrealManagerApp.instance.id">ID</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                  </th>
                  <th className="hand" onClick={sort('osType')}>
                    <Translate contentKey="surrealManagerApp.instance.osType">Os Type</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('osType')} />
                  </th>
                  <th className="hand" onClick={sort('currentState')}>
                    <Translate contentKey="surrealManagerApp.instance.currentState">Current State</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('currentState')} />
                  </th>
                  <th className="hand" onClick={sort('experienceName')}>
                    <Translate contentKey="surrealManagerApp.instance.experienceName">Experience Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('experienceName')} />
                  </th>
                  <th className="hand" onClick={sort('resourceGroupName')}>
                    <Translate contentKey="surrealManagerApp.instance.resourceGroupName">Resource Group Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('resourceGroupName')} />
                  </th>
                  <th className="hand" onClick={sort('regionName')}>
                    <Translate contentKey="surrealManagerApp.instance.regionName">Region Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('regionName')} />
                  </th>
                  <th className="hand" onClick={sort('vmName')}>
                    <Translate contentKey="surrealManagerApp.instance.vmName">Vm Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('vmName')} />
                  </th>
                  <th className="hand" onClick={sort('vmId')}>
                    <Translate contentKey="surrealManagerApp.instance.vmId">Vm Id</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('vmId')} />
                  </th>
                  <th className="hand" onClick={sort('friendlyVmId')}>
                    <Translate contentKey="surrealManagerApp.instance.friendlyVmId">Friendly Vm Id</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('friendlyVmId')} />
                  </th>
                  <th className="hand" onClick={sort('ipAddressToUse')}>
                    <Translate contentKey="surrealManagerApp.instance.ipAddressToUse">Ip Address To Use</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('ipAddressToUse')} />
                  </th>
                  <th className="hand" onClick={sort('vmPort')}>
                    <Translate contentKey="surrealManagerApp.instance.vmPort">Vm Port</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('vmPort')} />
                  </th>
                  <th className="hand" onClick={sort('vmSize')}>
                    <Translate contentKey="surrealManagerApp.instance.vmSize">Vm Size</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('vmSize')} />
                  </th>
                  <th className="hand" onClick={sort('diskLocation')}>
                    <Translate contentKey="surrealManagerApp.instance.diskLocation">Disk Location</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('diskLocation')} />
                  </th>
                  <th className="hand" onClick={sort('imageName')}>
                    <Translate contentKey="surrealManagerApp.instance.imageName">Image Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('imageName')} />
                  </th>
                  <th className="hand" onClick={sort('surrealGameName')}>
                    <Translate contentKey="surrealManagerApp.instance.surrealGameName">Surreal Game Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('surrealGameName')} />
                  </th>
                  <th className="hand" onClick={sort('vmUserName')}>
                    <Translate contentKey="surrealManagerApp.instance.vmUserName">Vm User Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('vmUserName')} />
                  </th>
                  <th className="hand" onClick={sort('vmUserPassword')}>
                    <Translate contentKey="surrealManagerApp.instance.vmUserPassword">Vm User Password</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('vmUserPassword')} />
                  </th>
                  <th className="hand" onClick={sort('sshUserName')}>
                    <Translate contentKey="surrealManagerApp.instance.sshUserName">Ssh User Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('sshUserName')} />
                  </th>
                  <th className="hand" onClick={sort('sshPassword')}>
                    <Translate contentKey="surrealManagerApp.instance.sshPassword">Ssh Password</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('sshPassword')} />
                  </th>
                  <th className="hand" onClick={sort('sshPublicKey')}>
                    <Translate contentKey="surrealManagerApp.instance.sshPublicKey">Ssh Public Key</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('sshPublicKey')} />
                  </th>
                  <th className="hand" onClick={sort('preProcessingCommands')}>
                    <Translate contentKey="surrealManagerApp.instance.preProcessingCommands">Pre Processing Commands</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('preProcessingCommands')} />
                  </th>
                  <th className="hand" onClick={sort('postProcessingCommands')}>
                    <Translate contentKey="surrealManagerApp.instance.postProcessingCommands">Post Processing Commands</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('postProcessingCommands')} />
                  </th>
                  <th className="hand" onClick={sort('signalingServerIP')}>
                    <Translate contentKey="surrealManagerApp.instance.signalingServerIP">Signaling Server IP</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('signalingServerIP')} />
                  </th>
                  <th className="hand" onClick={sort('signalingServerPort')}>
                    <Translate contentKey="surrealManagerApp.instance.signalingServerPort">Signaling Server Port</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('signalingServerPort')} />
                  </th>
                  <th className="hand" onClick={sort('publicIP')}>
                    <Translate contentKey="surrealManagerApp.instance.publicIP">Public IP</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('publicIP')} />
                  </th>
                  <th className="hand" onClick={sort('privateIP')}>
                    <Translate contentKey="surrealManagerApp.instance.privateIP">Private IP</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('privateIP')} />
                  </th>
                  <th className="hand" onClick={sort('dnsNameURL')}>
                    <Translate contentKey="surrealManagerApp.instance.dnsNameURL">Dns Name URL</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('dnsNameURL')} />
                  </th>
                  <th className="hand" onClick={sort('streamerConnected')}>
                    <Translate contentKey="surrealManagerApp.instance.streamerConnected">Streamer Connected</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('streamerConnected')} />
                  </th>
                  <th className="hand" onClick={sort('userConnected')}>
                    <Translate contentKey="surrealManagerApp.instance.userConnected">User Connected</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('userConnected')} />
                  </th>
                  <th className="hand" onClick={sort('hasError')}>
                    <Translate contentKey="surrealManagerApp.instance.hasError">Has Error</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('hasError')} />
                  </th>
                  <th className="hand" onClick={sort('connectedClientIP')}>
                    <Translate contentKey="surrealManagerApp.instance.connectedClientIP">Connected Client IP</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('connectedClientIP')} />
                  </th>
                  <th className="hand" onClick={sort('connectedUserId')}>
                    <Translate contentKey="surrealManagerApp.instance.connectedUserId">Connected User Id</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('connectedUserId')} />
                  </th>
                  <th className="hand" onClick={sort('currentConnectionStarted')}>
                    <Translate contentKey="surrealManagerApp.instance.currentConnectionStarted">Current Connection Started</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('currentConnectionStarted')} />
                  </th>
                  <th className="hand" onClick={sort('lastConnectionEnded')}>
                    <Translate contentKey="surrealManagerApp.instance.lastConnectionEnded">Last Connection Ended</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('lastConnectionEnded')} />
                  </th>
                  <th className="hand" onClick={sort('lastContactFromStreamer')}>
                    <Translate contentKey="surrealManagerApp.instance.lastContactFromStreamer">Last Contact From Streamer</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('lastContactFromStreamer')} />
                  </th>
                  <th className="hand" onClick={sort('isTemplate')}>
                    <Translate contentKey="surrealManagerApp.instance.isTemplate">Is Template</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('isTemplate')} />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {instanceList.map((instance, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/instance/${instance.id}`} color="link" size="sm">
                        {instance.id}
                      </Button>
                    </td>
                    <td>
                      <Translate contentKey={`surrealManagerApp.OsType.${instance.osType}`} />
                    </td>
                    <td>
                      <Translate contentKey={`surrealManagerApp.CurrentState.${instance.currentState}`} />
                    </td>
                    <td>{instance.experienceName}</td>
                    <td>{instance.resourceGroupName}</td>
                    <td>{instance.regionName}</td>
                    <td>{instance.vmName}</td>
                    <td>{instance.vmId}</td>
                    <td>{instance.friendlyVmId}</td>
                    <td>{instance.ipAddressToUse}</td>
                    <td>{instance.vmPort}</td>
                    <td>{instance.vmSize}</td>
                    <td>{instance.diskLocation}</td>
                    <td>{instance.imageName}</td>
                    <td>{instance.surrealGameName}</td>
                    <td>{instance.vmUserName}</td>
                    <td>{instance.vmUserPassword}</td>
                    <td>{instance.sshUserName}</td>
                    <td>{instance.sshPassword}</td>
                    <td>{instance.sshPublicKey}</td>
                    <td>{instance.preProcessingCommands}</td>
                    <td>{instance.postProcessingCommands}</td>
                    <td>{instance.signalingServerIP}</td>
                    <td>{instance.signalingServerPort}</td>
                    <td>{instance.publicIP}</td>
                    <td>{instance.privateIP}</td>
                    <td>{instance.dnsNameURL}</td>
                    <td>{instance.streamerConnected ? 'true' : 'false'}</td>
                    <td>{instance.userConnected ? 'true' : 'false'}</td>
                    <td>{instance.hasError ? 'true' : 'false'}</td>
                    <td>{instance.connectedClientIP}</td>
                    <td>{instance.connectedUserId}</td>
                    <td>
                      {instance.currentConnectionStarted ? (
                        <TextFormat type="date" value={instance.currentConnectionStarted} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {instance.lastConnectionEnded ? (
                        <TextFormat type="date" value={instance.lastConnectionEnded} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {instance.lastContactFromStreamer ? (
                        <TextFormat type="date" value={instance.lastContactFromStreamer} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{instance.isTemplate ? 'true' : 'false'}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`/instance/${instance.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`/instance/${instance.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`/instance/${instance.id}/delete`} color="danger" size="sm" data-cy="entityDeleteButton">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="surrealManagerApp.instance.home.notFound">No Instances found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Instance;
