import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IAgenda } from 'app/shared/model/agenda.model';
import { getEntities as getAgenda } from 'app/entities/agenda/agenda.reducer';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';
import { getEntities as getSurrealSubExperiences } from 'app/entities/surreal-sub-experience/surreal-sub-experience.reducer';
import { IPlayer } from 'app/shared/model/player.model';
import { getEntities as getPlayers } from 'app/entities/player/player.reducer';
import { ISurrealUser } from 'app/shared/model/surreal-user.model';
import { getEntities as getSurrealUsers } from 'app/entities/surreal-user/surreal-user.reducer';
import { ISurrealExperienceUser } from 'app/shared/model/surreal-experience-user.model';
import { getEntity, updateEntity, createEntity, reset } from './surreal-experience-user.reducer';

export const SurrealExperienceUserUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const agenda = useAppSelector(state => state.agenda.entities);
  const surrealSubExperiences = useAppSelector(state => state.surrealSubExperience.entities);
  const players = useAppSelector(state => state.player.entities);
  const surrealUsers = useAppSelector(state => state.surrealUser.entities);
  const surrealExperienceUserEntity = useAppSelector(state => state.surrealExperienceUser.entity);
  const loading = useAppSelector(state => state.surrealExperienceUser.loading);
  const updating = useAppSelector(state => state.surrealExperienceUser.updating);
  const updateSuccess = useAppSelector(state => state.surrealExperienceUser.updateSuccess);

  const handleClose = () => {
    navigate('/surreal-experience-user');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getAgenda({}));
    dispatch(getSurrealSubExperiences({}));
    dispatch(getPlayers({}));
    dispatch(getSurrealUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.canEnterEventTime = convertDateTimeToServer(values.canEnterEventTime);
    values.mustLeaveEventTime = convertDateTimeToServer(values.mustLeaveEventTime);

    const entity = {
      ...surrealExperienceUserEntity,
      ...values,
      agenda: agenda.find(it => it.id.toString() === values.agenda.toString()),
      surrealSubExperience: surrealSubExperiences.find(it => it.id.toString() === values.surrealSubExperience.toString()),
      player: players.find(it => it.id.toString() === values.player.toString()),
      surrealUser: surrealUsers.find(it => it.id.toString() === values.surrealUser.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          canEnterEventTime: displayDefaultDateTime(),
          mustLeaveEventTime: displayDefaultDateTime(),
        }
      : {
          ...surrealExperienceUserEntity,
          canEnterEventTime: convertDateTimeFromServer(surrealExperienceUserEntity.canEnterEventTime),
          mustLeaveEventTime: convertDateTimeFromServer(surrealExperienceUserEntity.mustLeaveEventTime),
          agenda: surrealExperienceUserEntity?.agenda?.id,
          surrealSubExperience: surrealExperienceUserEntity?.surrealSubExperience?.id,
          player: surrealExperienceUserEntity?.player?.id,
          surrealUser: surrealExperienceUserEntity?.surrealUser?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.surrealExperienceUser.home.createOrEditLabel" data-cy="SurrealExperienceUserCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.surrealExperienceUser.home.createOrEditLabel">
              Create or edit a SurrealExperienceUser
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="surreal-experience-user-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.surrealExperienceUser.canEnterEventTime')}
                id="surreal-experience-user-canEnterEventTime"
                name="canEnterEventTime"
                data-cy="canEnterEventTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealExperienceUser.mustLeaveEventTime')}
                id="surreal-experience-user-mustLeaveEventTime"
                name="mustLeaveEventTime"
                data-cy="mustLeaveEventTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealExperienceUser.isBanned')}
                id="surreal-experience-user-isBanned"
                name="isBanned"
                data-cy="isBanned"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealExperienceUser.isActiveStatus')}
                id="surreal-experience-user-isActiveStatus"
                name="isActiveStatus"
                data-cy="isActiveStatus"
                check
                type="checkbox"
              />
              <ValidatedField
                id="surreal-experience-user-agenda"
                name="agenda"
                data-cy="agenda"
                label={translate('surrealManagerApp.surrealExperienceUser.agenda')}
                type="select"
              >
                <option value="" key="0" />
                {agenda
                  ? agenda.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-experience-user-surrealSubExperience"
                name="surrealSubExperience"
                data-cy="surrealSubExperience"
                label={translate('surrealManagerApp.surrealExperienceUser.surrealSubExperience')}
                type="select"
              >
                <option value="" key="0" />
                {surrealSubExperiences
                  ? surrealSubExperiences.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-experience-user-player"
                name="player"
                data-cy="player"
                label={translate('surrealManagerApp.surrealExperienceUser.player')}
                type="select"
              >
                <option value="" key="0" />
                {players
                  ? players.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-experience-user-surrealUser"
                name="surrealUser"
                data-cy="surrealUser"
                label={translate('surrealManagerApp.surrealExperienceUser.surrealUser')}
                type="select"
              >
                <option value="" key="0" />
                {surrealUsers
                  ? surrealUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/surreal-experience-user" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SurrealExperienceUserUpdate;
