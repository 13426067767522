import { IEmiratesAgenda } from 'app/shared/model/emirates-agenda.model';
import { ISurrealUser } from 'app/shared/model/surreal-user.model';
import { InviteesStatus } from 'app/shared/model/enumerations/invitees-status.model';

export interface IInvitees {
  id?: number;
  status?: keyof typeof InviteesStatus | null;
  emiratesAgenda?: IEmiratesAgenda | null;
  surrealUsers?: ISurrealUser | null;
}

export const defaultValue: Readonly<IInvitees> = {};
