import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './schedules-for-experience-gpu-fleet.reducer';

export const SchedulesForExperienceGPUFleetDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const schedulesForExperienceGPUFleetEntity = useAppSelector(state => state.schedulesForExperienceGPUFleet.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="schedulesForExperienceGPUFleetDetailsHeading">
          <Translate contentKey="surrealManagerApp.schedulesForExperienceGPUFleet.detail.title">SchedulesForExperienceGPUFleet</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{schedulesForExperienceGPUFleetEntity.id}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="surrealManagerApp.schedulesForExperienceGPUFleet.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{schedulesForExperienceGPUFleetEntity.isActive ? 'true' : 'false'}</dd>
          <dt>
            <span id="startedAt">
              <Translate contentKey="surrealManagerApp.schedulesForExperienceGPUFleet.startedAt">Started At</Translate>
            </span>
          </dt>
          <dd>
            {schedulesForExperienceGPUFleetEntity.startedAt ? (
              <TextFormat value={schedulesForExperienceGPUFleetEntity.startedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endedAt">
              <Translate contentKey="surrealManagerApp.schedulesForExperienceGPUFleet.endedAt">Ended At</Translate>
            </span>
          </dt>
          <dd>
            {schedulesForExperienceGPUFleetEntity.endedAt ? (
              <TextFormat value={schedulesForExperienceGPUFleetEntity.endedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="affectedGPUCountInLastRun">
              <Translate contentKey="surrealManagerApp.schedulesForExperienceGPUFleet.affectedGPUCountInLastRun">
                Affected GPU Count In Last Run
              </Translate>
            </span>
          </dt>
          <dd>{schedulesForExperienceGPUFleetEntity.affectedGPUCountInLastRun}</dd>
        </dl>
        <Button tag={Link} to="/schedules-for-experience-gpu-fleet" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/schedules-for-experience-gpu-fleet/${schedulesForExperienceGPUFleetEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SchedulesForExperienceGPUFleetDetail;
