import { IAddress } from 'app/shared/model/address.model';
import { IAppearance } from 'app/shared/model/appearance.model';
import { IClientOrganization } from 'app/shared/model/client-organization.model';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';
import { IInstanceReport } from 'app/shared/model/instance-report.model';

export interface IOwner {
  id?: number;
  name?: string;
  phone?: string | null;
  email?: string | null;
  mailingAddress?: IAddress | null;
  officialAddress?: IAddress | null;
  ownerAppearance?: IAppearance | null;
  clientOrganizations?: IClientOrganization[] | null;
  surrealMainExperiences?: ISurrealMainExperience[] | null;
  instanceReports?: IInstanceReport[] | null;
}

export const defaultValue: Readonly<IOwner> = {};
