import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CloudAccountSecret from './cloud-account-secret';
import CloudAccountSecretDetail from './cloud-account-secret-detail';
import CloudAccountSecretUpdate from './cloud-account-secret-update';
import CloudAccountSecretDeleteDialog from './cloud-account-secret-delete-dialog';

const CloudAccountSecretRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CloudAccountSecret />} />
    <Route path="new" element={<CloudAccountSecretUpdate />} />
    <Route path=":id">
      <Route index element={<CloudAccountSecretDetail />} />
      <Route path="edit" element={<CloudAccountSecretUpdate />} />
      <Route path="delete" element={<CloudAccountSecretDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CloudAccountSecretRoutes;
