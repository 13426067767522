import { IImageFile } from 'app/shared/model/image-file.model';
import { IEventAppearance } from 'app/shared/model/event-appearance.model';
import { IOwner } from 'app/shared/model/owner.model';

export interface IAppearance {
  id?: number;
  primaryColor?: string | null;
  primaryFont?: string | null;
  primaryStyleCSSContentType?: string | null;
  primaryStyleCSS?: string | null;
  secondaryColor?: string | null;
  secondaryFont?: string | null;
  secondaryStyleCSSContentType?: string | null;
  secondaryStyleCSS?: string | null;
  loadingScreen?: IImageFile | null;
  logo?: IImageFile | null;
  event?: IEventAppearance | null;
  owner?: IOwner | null;
}

export const defaultValue: Readonly<IAppearance> = {};
