import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './event-type.reducer';

export const EventTypeDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const eventTypeEntity = useAppSelector(state => state.eventType.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="eventTypeDetailsHeading">
          <Translate contentKey="surrealManagerApp.eventType.detail.title">EventType</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{eventTypeEntity.id}</dd>
          <dt>
            <span id="clientName">
              <Translate contentKey="surrealManagerApp.eventType.clientName">Client Name</Translate>
            </span>
          </dt>
          <dd>{eventTypeEntity.clientName}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="surrealManagerApp.eventType.description">Description</Translate>
            </span>
          </dt>
          <dd>{eventTypeEntity.description}</dd>
          <dt>
            <span id="eventTypeCreationTimestamp">
              <Translate contentKey="surrealManagerApp.eventType.eventTypeCreationTimestamp">Event Type Creation Timestamp</Translate>
            </span>
          </dt>
          <dd>
            {eventTypeEntity.eventTypeCreationTimestamp ? (
              <TextFormat value={eventTypeEntity.eventTypeCreationTimestamp} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="eventName">
              <Translate contentKey="surrealManagerApp.eventType.eventName">Event Name</Translate>
            </span>
          </dt>
          <dd>{eventTypeEntity.eventName}</dd>
        </dl>
        <Button tag={Link} to="/event-type" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/event-type/${eventTypeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EventTypeDetail;
