import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './emirates-agenda.reducer';

export const EmiratesAgendaDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const emiratesAgendaEntity = useAppSelector(state => state.emiratesAgenda.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="emiratesAgendaDetailsHeading">
          <Translate contentKey="surrealManagerApp.emiratesAgenda.detail.title">EmiratesAgenda</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{emiratesAgendaEntity.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="surrealManagerApp.emiratesAgenda.title">Title</Translate>
            </span>
          </dt>
          <dd>{emiratesAgendaEntity.title}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="surrealManagerApp.emiratesAgenda.description">Description</Translate>
            </span>
          </dt>
          <dd>{emiratesAgendaEntity.description}</dd>
          <dt>
            <span id="startTime">
              <Translate contentKey="surrealManagerApp.emiratesAgenda.startTime">Start Time</Translate>
            </span>
          </dt>
          <dd>
            {emiratesAgendaEntity.startTime ? (
              <TextFormat value={emiratesAgendaEntity.startTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endTime">
              <Translate contentKey="surrealManagerApp.emiratesAgenda.endTime">End Time</Translate>
            </span>
          </dt>
          <dd>
            {emiratesAgendaEntity.endTime ? <TextFormat value={emiratesAgendaEntity.endTime} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="location">
              <Translate contentKey="surrealManagerApp.emiratesAgenda.location">Location</Translate>
            </span>
          </dt>
          <dd>{emiratesAgendaEntity.location}</dd>
          <dt>
            <span id="levelName">
              <Translate contentKey="surrealManagerApp.emiratesAgenda.levelName">Level Name</Translate>
            </span>
          </dt>
          <dd>{emiratesAgendaEntity.levelName}</dd>
          <dt>
            <span id="groupName">
              <Translate contentKey="surrealManagerApp.emiratesAgenda.groupName">Group Name</Translate>
            </span>
          </dt>
          <dd>{emiratesAgendaEntity.groupName}</dd>
          <dt>
            <span id="notified">
              <Translate contentKey="surrealManagerApp.emiratesAgenda.notified">Notified</Translate>
            </span>
          </dt>
          <dd>{emiratesAgendaEntity.notified ? 'true' : 'false'}</dd>
          <dt>
            <span id="isPrivate">
              <Translate contentKey="surrealManagerApp.emiratesAgenda.isPrivate">Is Private</Translate>
            </span>
          </dt>
          <dd>{emiratesAgendaEntity.isPrivate ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.emiratesAgenda.surrealSubExperience">Surreal Sub Experience</Translate>
          </dt>
          <dd>{emiratesAgendaEntity.surrealSubExperience ? emiratesAgendaEntity.surrealSubExperience.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.emiratesAgenda.createdBy">Created By</Translate>
          </dt>
          <dd>{emiratesAgendaEntity.createdBy ? emiratesAgendaEntity.createdBy.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/emirates-agenda" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/emirates-agenda/${emiratesAgendaEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EmiratesAgendaDetail;
