import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities, reset } from './surreal-sub-experience.reducer';

export const SurrealSubExperience = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [sorting, setSorting] = useState(false);

  const surrealSubExperienceList = useAppSelector(state => state.surrealSubExperience.entities);
  const loading = useAppSelector(state => state.surrealSubExperience.loading);
  const links = useAppSelector(state => state.surrealSubExperience.links);
  const updateSuccess = useAppSelector(state => state.surrealSubExperience.updateSuccess);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setSorting(true);
  };

  const handleSyncList = () => {
    resetAll();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="surreal-sub-experience-heading" data-cy="SurrealSubExperienceHeading">
        <Translate contentKey="surrealManagerApp.surrealSubExperience.home.title">Surreal Sub Experiences</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="surrealManagerApp.surrealSubExperience.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to="/surreal-sub-experience/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="surrealManagerApp.surrealSubExperience.home.createLabel">Create new Surreal Sub Experience</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          dataLength={surrealSubExperienceList ? surrealSubExperienceList.length : 0}
          next={handleLoadMore}
          hasMore={paginationState.activePage - 1 < links.next}
          loader={<div className="loader">Loading ...</div>}
        >
          {surrealSubExperienceList && surrealSubExperienceList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.id">ID</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                  </th>
                  <th className="hand" onClick={sort('experienceName')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.experienceName">Experience Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('experienceName')} />
                  </th>
                  <th className="hand" onClick={sort('startAt')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.startAt">Start At</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('startAt')} />
                  </th>
                  <th className="hand" onClick={sort('endAt')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.endAt">End At</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('endAt')} />
                  </th>
                  <th className="hand" onClick={sort('isRecurring')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.isRecurring">Is Recurring</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('isRecurring')} />
                  </th>
                  <th className="hand" onClick={sort('useMatchmaker')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.useMatchmaker">Use Matchmaker</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('useMatchmaker')} />
                  </th>
                  <th className="hand" onClick={sort('matchmakerServerIP')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.matchmakerServerIP">Matchmaker Server IP</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('matchmakerServerIP')} />
                  </th>
                  <th className="hand" onClick={sort('matchmakerServerPort')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.matchmakerServerPort">Matchmaker Server Port</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('matchmakerServerPort')} />
                  </th>
                  <th className="hand" onClick={sort('turnServerURL')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.turnServerURL">Turn Server URL</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('turnServerURL')} />
                  </th>
                  <th className="hand" onClick={sort('turnServerPort')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.turnServerPort">Turn Server Port</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('turnServerPort')} />
                  </th>
                  <th className="hand" onClick={sort('stunServerURL')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.stunServerURL">Stun Server URL</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('stunServerURL')} />
                  </th>
                  <th className="hand" onClick={sort('stunServerPort')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.stunServerPort">Stun Server Port</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('stunServerPort')} />
                  </th>
                  <th className="hand" onClick={sort('isActive')}>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.isActive">Is Active</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('isActive')} />
                  </th>
                  <th>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.eventAppearance">Event Appearance</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.eventIntegration">Event Integration</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.descriptor">Descriptor</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.mapPointList">Map Point List</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.subExperienceEnvironment">
                      Sub Experience Environment
                    </Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="surrealManagerApp.surrealSubExperience.makesUp">Makes Up</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {surrealSubExperienceList.map((surrealSubExperience, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/surreal-sub-experience/${surrealSubExperience.id}`} color="link" size="sm">
                        {surrealSubExperience.id}
                      </Button>
                    </td>
                    <td>{surrealSubExperience.experienceName}</td>
                    <td>
                      {surrealSubExperience.startAt ? (
                        <TextFormat type="date" value={surrealSubExperience.startAt} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {surrealSubExperience.endAt ? (
                        <TextFormat type="date" value={surrealSubExperience.endAt} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{surrealSubExperience.isRecurring ? 'true' : 'false'}</td>
                    <td>{surrealSubExperience.useMatchmaker ? 'true' : 'false'}</td>
                    <td>{surrealSubExperience.matchmakerServerIP}</td>
                    <td>{surrealSubExperience.matchmakerServerPort}</td>
                    <td>{surrealSubExperience.turnServerURL}</td>
                    <td>{surrealSubExperience.turnServerPort}</td>
                    <td>{surrealSubExperience.stunServerURL}</td>
                    <td>{surrealSubExperience.stunServerPort}</td>
                    <td>{surrealSubExperience.isActive ? 'true' : 'false'}</td>
                    <td>
                      {surrealSubExperience.eventAppearance ? (
                        <Link to={`/event-appearance/${surrealSubExperience.eventAppearance.id}`}>
                          {surrealSubExperience.eventAppearance.id}
                        </Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {surrealSubExperience.eventIntegration ? (
                        <Link to={`/event-integration/${surrealSubExperience.eventIntegration.id}`}>
                          {surrealSubExperience.eventIntegration.id}
                        </Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {surrealSubExperience.descriptor ? (
                        <Link to={`/descriptor/${surrealSubExperience.descriptor.id}`}>{surrealSubExperience.descriptor.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {surrealSubExperience.mapPointList ? (
                        <Link to={`/map-point-list/${surrealSubExperience.mapPointList.id}`}>{surrealSubExperience.mapPointList.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {surrealSubExperience.subExperienceEnvironment ? (
                        <Link to={`/sub-experience-environment/${surrealSubExperience.subExperienceEnvironment.id}`}>
                          {surrealSubExperience.subExperienceEnvironment.id}
                        </Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {surrealSubExperience.makesUp ? (
                        <Link to={`/surreal-main-experience/${surrealSubExperience.makesUp.id}`}>{surrealSubExperience.makesUp.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/surreal-sub-experience/${surrealSubExperience.id}`}
                          color="info"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/surreal-sub-experience/${surrealSubExperience.id}/edit`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/surreal-sub-experience/${surrealSubExperience.id}/delete`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="surrealManagerApp.surrealSubExperience.home.notFound">No Surreal Sub Experiences found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default SurrealSubExperience;
