import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EventIntegration from './event-integration';
import EventIntegrationDetail from './event-integration-detail';
import EventIntegrationUpdate from './event-integration-update';
import EventIntegrationDeleteDialog from './event-integration-delete-dialog';

const EventIntegrationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EventIntegration />} />
    <Route path="new" element={<EventIntegrationUpdate />} />
    <Route path=":id">
      <Route index element={<EventIntegrationDetail />} />
      <Route path="edit" element={<EventIntegrationUpdate />} />
      <Route path="delete" element={<EventIntegrationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EventIntegrationRoutes;
