import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ILeaderboard } from 'app/shared/model/leaderboard.model';
import { getEntities as getLeaderboards } from 'app/entities/leaderboard/leaderboard.reducer';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';
import { getEntities as getSurrealMainExperiences } from 'app/entities/surreal-main-experience/surreal-main-experience.reducer';
import { IMiniGame } from 'app/shared/model/mini-game.model';
import { getEntity, updateEntity, createEntity, reset } from './mini-game.reducer';

export const MiniGameUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const leaderboards = useAppSelector(state => state.leaderboard.entities);
  const surrealMainExperiences = useAppSelector(state => state.surrealMainExperience.entities);
  const miniGameEntity = useAppSelector(state => state.miniGame.entity);
  const loading = useAppSelector(state => state.miniGame.loading);
  const updating = useAppSelector(state => state.miniGame.updating);
  const updateSuccess = useAppSelector(state => state.miniGame.updateSuccess);

  const handleClose = () => {
    navigate('/mini-game');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getLeaderboards({}));
    dispatch(getSurrealMainExperiences({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...miniGameEntity,
      ...values,
      leaderboard: leaderboards.find(it => it.id.toString() === values.leaderboard.toString()),
      surrealMainExperience: surrealMainExperiences.find(it => it.id.toString() === values.surrealMainExperience.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...miniGameEntity,
          leaderboard: miniGameEntity?.leaderboard?.id,
          surrealMainExperience: miniGameEntity?.surrealMainExperience?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.miniGame.home.createOrEditLabel" data-cy="MiniGameCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.miniGame.home.createOrEditLabel">Create or edit a MiniGame</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="mini-game-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.miniGame.name')}
                id="mini-game-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.miniGame.instructions')}
                id="mini-game-instructions"
                name="instructions"
                data-cy="instructions"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.miniGame.notes')}
                id="mini-game-notes"
                name="notes"
                data-cy="notes"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.miniGame.isEnabled')}
                id="mini-game-isEnabled"
                name="isEnabled"
                data-cy="isEnabled"
                check
                type="checkbox"
              />
              <ValidatedField
                id="mini-game-leaderboard"
                name="leaderboard"
                data-cy="leaderboard"
                label={translate('surrealManagerApp.miniGame.leaderboard')}
                type="select"
              >
                <option value="" key="0" />
                {leaderboards
                  ? leaderboards.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="mini-game-surrealMainExperience"
                name="surrealMainExperience"
                data-cy="surrealMainExperience"
                label={translate('surrealManagerApp.miniGame.surrealMainExperience')}
                type="select"
              >
                <option value="" key="0" />
                {surrealMainExperiences
                  ? surrealMainExperiences.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/mini-game" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MiniGameUpdate;
