import { IMapPoint } from 'app/shared/model/map-point.model';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';

export interface IMapPointList {
  id?: number;
  mapPoints?: IMapPoint[] | null;
  surrealMainExperience?: ISurrealMainExperience | null;
  surrealSubExperience?: ISurrealSubExperience | null;
}

export const defaultValue: Readonly<IMapPointList> = {};
