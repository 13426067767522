import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ItemToSell from './item-to-sell';
import ItemToSellDetail from './item-to-sell-detail';
import ItemToSellUpdate from './item-to-sell-update';
import ItemToSellDeleteDialog from './item-to-sell-delete-dialog';

const ItemToSellRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ItemToSell />} />
    <Route path="new" element={<ItemToSellUpdate />} />
    <Route path=":id">
      <Route index element={<ItemToSellDetail />} />
      <Route path="edit" element={<ItemToSellUpdate />} />
      <Route path="delete" element={<ItemToSellDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ItemToSellRoutes;
