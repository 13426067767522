import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IInstance } from 'app/shared/model/instance.model';
import { OsType } from 'app/shared/model/enumerations/os-type.model';
import { CurrentState } from 'app/shared/model/enumerations/current-state.model';
import { getEntity, updateEntity, createEntity, reset } from './instance.reducer';

export const InstanceUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const instanceEntity = useAppSelector(state => state.instance.entity);
  const loading = useAppSelector(state => state.instance.loading);
  const updating = useAppSelector(state => state.instance.updating);
  const updateSuccess = useAppSelector(state => state.instance.updateSuccess);
  const osTypeValues = Object.keys(OsType);
  const currentStateValues = Object.keys(CurrentState);

  const handleClose = () => {
    navigate('/instance');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.currentConnectionStarted = convertDateTimeToServer(values.currentConnectionStarted);
    values.lastConnectionEnded = convertDateTimeToServer(values.lastConnectionEnded);
    values.lastContactFromStreamer = convertDateTimeToServer(values.lastContactFromStreamer);

    const entity = {
      ...instanceEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          currentConnectionStarted: displayDefaultDateTime(),
          lastConnectionEnded: displayDefaultDateTime(),
          lastContactFromStreamer: displayDefaultDateTime(),
        }
      : {
          osType: 'LINUX',
          currentState: 'AVAILABLE',
          ...instanceEntity,
          currentConnectionStarted: convertDateTimeFromServer(instanceEntity.currentConnectionStarted),
          lastConnectionEnded: convertDateTimeFromServer(instanceEntity.lastConnectionEnded),
          lastContactFromStreamer: convertDateTimeFromServer(instanceEntity.lastContactFromStreamer),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.instance.home.createOrEditLabel" data-cy="InstanceCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.instance.home.createOrEditLabel">Create or edit a Instance</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="instance-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.instance.osType')}
                id="instance-osType"
                name="osType"
                data-cy="osType"
                type="select"
              >
                {osTypeValues.map(osType => (
                  <option value={osType} key={osType}>
                    {translate('surrealManagerApp.OsType.' + osType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('surrealManagerApp.instance.currentState')}
                id="instance-currentState"
                name="currentState"
                data-cy="currentState"
                type="select"
              >
                {currentStateValues.map(currentState => (
                  <option value={currentState} key={currentState}>
                    {translate('surrealManagerApp.CurrentState.' + currentState)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('surrealManagerApp.instance.experienceName')}
                id="instance-experienceName"
                name="experienceName"
                data-cy="experienceName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.resourceGroupName')}
                id="instance-resourceGroupName"
                name="resourceGroupName"
                data-cy="resourceGroupName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.regionName')}
                id="instance-regionName"
                name="regionName"
                data-cy="regionName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.vmName')}
                id="instance-vmName"
                name="vmName"
                data-cy="vmName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.vmId')}
                id="instance-vmId"
                name="vmId"
                data-cy="vmId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.friendlyVmId')}
                id="instance-friendlyVmId"
                name="friendlyVmId"
                data-cy="friendlyVmId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.ipAddressToUse')}
                id="instance-ipAddressToUse"
                name="ipAddressToUse"
                data-cy="ipAddressToUse"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.vmPort')}
                id="instance-vmPort"
                name="vmPort"
                data-cy="vmPort"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.vmSize')}
                id="instance-vmSize"
                name="vmSize"
                data-cy="vmSize"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.diskLocation')}
                id="instance-diskLocation"
                name="diskLocation"
                data-cy="diskLocation"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.imageName')}
                id="instance-imageName"
                name="imageName"
                data-cy="imageName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.surrealGameName')}
                id="instance-surrealGameName"
                name="surrealGameName"
                data-cy="surrealGameName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.vmUserName')}
                id="instance-vmUserName"
                name="vmUserName"
                data-cy="vmUserName"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.vmUserPassword')}
                id="instance-vmUserPassword"
                name="vmUserPassword"
                data-cy="vmUserPassword"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.sshUserName')}
                id="instance-sshUserName"
                name="sshUserName"
                data-cy="sshUserName"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.sshPassword')}
                id="instance-sshPassword"
                name="sshPassword"
                data-cy="sshPassword"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.sshPublicKey')}
                id="instance-sshPublicKey"
                name="sshPublicKey"
                data-cy="sshPublicKey"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.preProcessingCommands')}
                id="instance-preProcessingCommands"
                name="preProcessingCommands"
                data-cy="preProcessingCommands"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.postProcessingCommands')}
                id="instance-postProcessingCommands"
                name="postProcessingCommands"
                data-cy="postProcessingCommands"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.signalingServerIP')}
                id="instance-signalingServerIP"
                name="signalingServerIP"
                data-cy="signalingServerIP"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.signalingServerPort')}
                id="instance-signalingServerPort"
                name="signalingServerPort"
                data-cy="signalingServerPort"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.publicIP')}
                id="instance-publicIP"
                name="publicIP"
                data-cy="publicIP"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.privateIP')}
                id="instance-privateIP"
                name="privateIP"
                data-cy="privateIP"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.dnsNameURL')}
                id="instance-dnsNameURL"
                name="dnsNameURL"
                data-cy="dnsNameURL"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.streamerConnected')}
                id="instance-streamerConnected"
                name="streamerConnected"
                data-cy="streamerConnected"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.userConnected')}
                id="instance-userConnected"
                name="userConnected"
                data-cy="userConnected"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.hasError')}
                id="instance-hasError"
                name="hasError"
                data-cy="hasError"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.connectedClientIP')}
                id="instance-connectedClientIP"
                name="connectedClientIP"
                data-cy="connectedClientIP"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.connectedUserId')}
                id="instance-connectedUserId"
                name="connectedUserId"
                data-cy="connectedUserId"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.currentConnectionStarted')}
                id="instance-currentConnectionStarted"
                name="currentConnectionStarted"
                data-cy="currentConnectionStarted"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.lastConnectionEnded')}
                id="instance-lastConnectionEnded"
                name="lastConnectionEnded"
                data-cy="lastConnectionEnded"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.lastContactFromStreamer')}
                id="instance-lastContactFromStreamer"
                name="lastContactFromStreamer"
                data-cy="lastContactFromStreamer"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('surrealManagerApp.instance.isTemplate')}
                id="instance-isTemplate"
                name="isTemplate"
                data-cy="isTemplate"
                check
                type="checkbox"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/instance" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InstanceUpdate;
