import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, openFile, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './descriptor.reducer';

export const DescriptorDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const descriptorEntity = useAppSelector(state => state.descriptor.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="descriptorDetailsHeading">
          <Translate contentKey="surrealManagerApp.descriptor.detail.title">Descriptor</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.id}</dd>
          <dt>
            <span id="descriptorId">
              <Translate contentKey="surrealManagerApp.descriptor.descriptorId">Descriptor Id</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.descriptorId}</dd>
          <dt>
            <span id="begins">
              <Translate contentKey="surrealManagerApp.descriptor.begins">Begins</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.begins ? <TextFormat value={descriptorEntity.begins} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="ends">
              <Translate contentKey="surrealManagerApp.descriptor.ends">Ends</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.ends ? <TextFormat value={descriptorEntity.ends} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="surrealManagerApp.descriptor.title">Title</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.title}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="surrealManagerApp.descriptor.description">Description</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.description}</dd>
          <dt>
            <span id="subdomain">
              <Translate contentKey="surrealManagerApp.descriptor.subdomain">Subdomain</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.subdomain}</dd>
          <dt>
            <span id="defaultSpace">
              <Translate contentKey="surrealManagerApp.descriptor.defaultSpace">Default Space</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.defaultSpace}</dd>
          <dt>
            <span id="primaryColor">
              <Translate contentKey="surrealManagerApp.descriptor.primaryColor">Primary Color</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.primaryColor}</dd>
          <dt>
            <span id="secondaryColor">
              <Translate contentKey="surrealManagerApp.descriptor.secondaryColor">Secondary Color</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.secondaryColor}</dd>
          <dt>
            <span id="warningColor">
              <Translate contentKey="surrealManagerApp.descriptor.warningColor">Warning Color</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.warningColor}</dd>
          <dt>
            <span id="dangerColor">
              <Translate contentKey="surrealManagerApp.descriptor.dangerColor">Danger Color</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.dangerColor}</dd>
          <dt>
            <span id="googleFontUrl">
              <Translate contentKey="surrealManagerApp.descriptor.googleFontUrl">Google Font Url</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.googleFontUrl}</dd>
          <dt>
            <span id="channelCSS">
              <Translate contentKey="surrealManagerApp.descriptor.channelCSS">Channel CSS</Translate>
            </span>
          </dt>
          <dd>
            {descriptorEntity.channelCSS ? (
              <div>
                {descriptorEntity.channelCSSContentType ? (
                  <a onClick={openFile(descriptorEntity.channelCSSContentType, descriptorEntity.channelCSS)}>
                    <Translate contentKey="entity.action.open">Open</Translate>&nbsp;
                  </a>
                ) : null}
                <span>
                  {descriptorEntity.channelCSSContentType}, {byteSize(descriptorEntity.channelCSS)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="mapId">
              <Translate contentKey="surrealManagerApp.descriptor.mapId">Map Id</Translate>
            </span>
          </dt>
          <dd>{descriptorEntity.mapId}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.descriptor.imageFile">Image File</Translate>
          </dt>
          <dd>{descriptorEntity.imageFile ? descriptorEntity.imageFile.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/descriptor" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/descriptor/${descriptorEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DescriptorDetail;
