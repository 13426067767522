import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SmartScreen from './smart-screen';
import SmartScreenDetail from './smart-screen-detail';
import SmartScreenUpdate from './smart-screen-update';
import SmartScreenDeleteDialog from './smart-screen-delete-dialog';

const SmartScreenRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SmartScreen />} />
    <Route path="new" element={<SmartScreenUpdate />} />
    <Route path=":id">
      <Route index element={<SmartScreenDetail />} />
      <Route path="edit" element={<SmartScreenUpdate />} />
      <Route path="delete" element={<SmartScreenDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SmartScreenRoutes;
