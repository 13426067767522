import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm, ValidatedBlobField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ISurrealUser } from 'app/shared/model/surreal-user.model';
import { getEntities as getSurrealUsers } from 'app/entities/surreal-user/surreal-user.reducer';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';
import { getEntities as getSurrealMainExperiences } from 'app/entities/surreal-main-experience/surreal-main-experience.reducer';
import { ISurrealFile } from 'app/shared/model/surreal-file.model';
import { MimeType } from 'app/shared/model/enumerations/mime-type.model';
import { getEntity, updateEntity, createEntity, reset } from './surreal-file.reducer';

export const SurrealFileUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const surrealUsers = useAppSelector(state => state.surrealUser.entities);
  const surrealMainExperiences = useAppSelector(state => state.surrealMainExperience.entities);
  const surrealFileEntity = useAppSelector(state => state.surrealFile.entity);
  const loading = useAppSelector(state => state.surrealFile.loading);
  const updating = useAppSelector(state => state.surrealFile.updating);
  const updateSuccess = useAppSelector(state => state.surrealFile.updateSuccess);
  const mimeTypeValues = Object.keys(MimeType);

  const handleClose = () => {
    navigate('/surreal-file');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getSurrealUsers({}));
    dispatch(getSurrealMainExperiences({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...surrealFileEntity,
      ...values,
      surrealUser: surrealUsers.find(it => it.id.toString() === values.surrealUser.toString()),
      surrealMainExperience: surrealMainExperiences.find(it => it.id.toString() === values.surrealMainExperience.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          mimeType: 'IMAGE_PNG',
          ...surrealFileEntity,
          surrealUser: surrealFileEntity?.surrealUser?.id,
          surrealMainExperience: surrealFileEntity?.surrealMainExperience?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.surrealFile.home.createOrEditLabel" data-cy="SurrealFileCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.surrealFile.home.createOrEditLabel">Create or edit a SurrealFile</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="surreal-file-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.surrealFile.displayName')}
                id="surreal-file-displayName"
                name="displayName"
                data-cy="displayName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealFile.mimeType')}
                id="surreal-file-mimeType"
                name="mimeType"
                data-cy="mimeType"
                type="select"
              >
                {mimeTypeValues.map(mimeType => (
                  <option value={mimeType} key={mimeType}>
                    {translate('surrealManagerApp.MimeType.' + mimeType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedBlobField
                label={translate('surrealManagerApp.surrealFile.fileData')}
                id="surreal-file-fileData"
                name="fileData"
                data-cy="fileData"
                openActionLabel={translate('entity.action.open')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="surreal-file-surrealUser"
                name="surrealUser"
                data-cy="surrealUser"
                label={translate('surrealManagerApp.surrealFile.surrealUser')}
                type="select"
              >
                <option value="" key="0" />
                {surrealUsers
                  ? surrealUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-file-surrealMainExperience"
                name="surrealMainExperience"
                data-cy="surrealMainExperience"
                label={translate('surrealManagerApp.surrealFile.surrealMainExperience')}
                type="select"
              >
                <option value="" key="0" />
                {surrealMainExperiences
                  ? surrealMainExperiences.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/surreal-file" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SurrealFileUpdate;
