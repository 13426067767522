import { ISurrealExperienceUser } from 'app/shared/model/surreal-experience-user.model';

export interface ICoupon {
  id?: number;
  couponCode?: string | null;
  assignedToPersonId?: number | null;
  usedByPersonId?: number | null;
  offerId?: number | null;
  isActive?: boolean | null;
  surrealExperienceUser?: ISurrealExperienceUser | null;
}

export const defaultValue: Readonly<ICoupon> = {
  isActive: false,
};
