import dayjs from 'dayjs';
import { IAgenda } from 'app/shared/model/agenda.model';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';
import { IPlayer } from 'app/shared/model/player.model';
import { ISurrealUser } from 'app/shared/model/surreal-user.model';
import { ICoupon } from 'app/shared/model/coupon.model';
import { IEventRole } from 'app/shared/model/event-role.model';

export interface ISurrealExperienceUser {
  id?: number;
  canEnterEventTime?: string | null;
  mustLeaveEventTime?: string | null;
  isBanned?: boolean | null;
  isActiveStatus?: boolean | null;
  agenda?: IAgenda | null;
  surrealSubExperience?: ISurrealSubExperience | null;
  player?: IPlayer | null;
  surrealUser?: ISurrealUser | null;
  coupons?: ICoupon[] | null;
  roles?: IEventRole[] | null;
}

export const defaultValue: Readonly<ISurrealExperienceUser> = {
  isBanned: false,
  isActiveStatus: false,
};
