import { ISmartScreen } from 'app/shared/model/smart-screen.model';

export interface IVideoStream {
  id?: number;
  name?: string;
  locationUrl?: string;
  smartScreen?: ISmartScreen | null;
}

export const defaultValue: Readonly<IVideoStream> = {};
