import dayjs from 'dayjs';

export interface IRules {
  id?: number;
  minimumInstances?: number | null;
  maximumInstances?: number | null;
  stepUpIncrement?: number | null;
  stepDownIncrement?: number | null;
  bufferInstancesToKeep?: number | null;
  startRunningAt?: string | null;
  stopRunningAt?: string | null;
}

export const defaultValue: Readonly<IRules> = {};
