import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './environment.reducer';

export const EnvironmentDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const environmentEntity = useAppSelector(state => state.environment.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="environmentDetailsHeading">
          <Translate contentKey="surrealManagerApp.environment.detail.title">Environment</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.id}</dd>
          <dt>
            <span id="frontEndName">
              <Translate contentKey="surrealManagerApp.environment.frontEndName">Front End Name</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.frontEndName}</dd>
          <dt>
            <span id="frontEndLocationURL">
              <Translate contentKey="surrealManagerApp.environment.frontEndLocationURL">Front End Location URL</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.frontEndLocationURL}</dd>
          <dt>
            <span id="apiGatewayName">
              <Translate contentKey="surrealManagerApp.environment.apiGatewayName">Api Gateway Name</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.apiGatewayName}</dd>
          <dt>
            <span id="apiGatewayURL">
              <Translate contentKey="surrealManagerApp.environment.apiGatewayURL">Api Gateway URL</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.apiGatewayURL}</dd>
          <dt>
            <span id="webSocketURL">
              <Translate contentKey="surrealManagerApp.environment.webSocketURL">Web Socket URL</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.webSocketURL}</dd>
          <dt>
            <span id="webSocketPort">
              <Translate contentKey="surrealManagerApp.environment.webSocketPort">Web Socket Port</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.webSocketPort}</dd>
          <dt>
            <span id="useMatchmaker">
              <Translate contentKey="surrealManagerApp.environment.useMatchmaker">Use Matchmaker</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.useMatchmaker ? 'true' : 'false'}</dd>
          <dt>
            <span id="matchmakerServerIP">
              <Translate contentKey="surrealManagerApp.environment.matchmakerServerIP">Matchmaker Server IP</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.matchmakerServerIP}</dd>
          <dt>
            <span id="matchmakerServerPort">
              <Translate contentKey="surrealManagerApp.environment.matchmakerServerPort">Matchmaker Server Port</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.matchmakerServerPort}</dd>
          <dt>
            <span id="turnServerURL">
              <Translate contentKey="surrealManagerApp.environment.turnServerURL">Turn Server URL</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.turnServerURL}</dd>
          <dt>
            <span id="turnServerPort">
              <Translate contentKey="surrealManagerApp.environment.turnServerPort">Turn Server Port</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.turnServerPort}</dd>
          <dt>
            <span id="stunServerURL">
              <Translate contentKey="surrealManagerApp.environment.stunServerURL">Stun Server URL</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.stunServerURL}</dd>
          <dt>
            <span id="stunServerPort">
              <Translate contentKey="surrealManagerApp.environment.stunServerPort">Stun Server Port</Translate>
            </span>
          </dt>
          <dd>{environmentEntity.stunServerPort}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.environment.deploys">Deploys</Translate>
          </dt>
          <dd>{environmentEntity.deploys ? environmentEntity.deploys.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/environment" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/environment/${environmentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EnvironmentDetail;
