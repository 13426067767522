import dayjs from 'dayjs';
import { IInvitees } from 'app/shared/model/invitees.model';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';
import { ISurrealUser } from 'app/shared/model/surreal-user.model';

export interface IEmiratesAgenda {
  id?: number;
  title?: string | null;
  description?: string | null;
  startTime?: string;
  endTime?: string;
  location?: string | null;
  levelName?: string | null;
  groupName?: string | null;
  notified?: boolean | null;
  isPrivate?: boolean | null;
  invitees?: IInvitees[] | null;
  surrealSubExperience?: ISurrealSubExperience | null;
  createdBy?: ISurrealUser | null;
}

export const defaultValue: Readonly<IEmiratesAgenda> = {
  notified: false,
  isPrivate: false,
};
