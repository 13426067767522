import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getSurrealLevels } from 'app/entities/surreal-level/surreal-level.reducer';
import { IAppointment } from 'app/shared/model/appointment.model';
import { getEntities as getAppointments } from 'app/entities/appointment/appointment.reducer';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';
import { getEntities as getSurrealMainExperiences } from 'app/entities/surreal-main-experience/surreal-main-experience.reducer';
import { ISurrealLevel } from 'app/shared/model/surreal-level.model';
import { getEntity, updateEntity, createEntity, reset } from './surreal-level.reducer';

export const SurrealLevelUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const surrealLevels = useAppSelector(state => state.surrealLevel.entities);
  const appointments = useAppSelector(state => state.appointment.entities);
  const surrealMainExperiences = useAppSelector(state => state.surrealMainExperience.entities);
  const surrealLevelEntity = useAppSelector(state => state.surrealLevel.entity);
  const loading = useAppSelector(state => state.surrealLevel.loading);
  const updating = useAppSelector(state => state.surrealLevel.updating);
  const updateSuccess = useAppSelector(state => state.surrealLevel.updateSuccess);

  const handleClose = () => {
    navigate('/surreal-level');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getSurrealLevels({}));
    dispatch(getAppointments({}));
    dispatch(getSurrealMainExperiences({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...surrealLevelEntity,
      ...values,
      child: surrealLevels.find(it => it.id.toString() === values.child.toString()),
      surrealMainExperience: surrealMainExperiences.find(it => it.id.toString() === values.surrealMainExperience.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...surrealLevelEntity,
          child: surrealLevelEntity?.child?.id,
          surrealMainExperience: surrealLevelEntity?.surrealMainExperience?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.surrealLevel.home.createOrEditLabel" data-cy="SurrealLevelCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.surrealLevel.home.createOrEditLabel">Create or edit a SurrealLevel</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="surreal-level-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.surrealLevel.levelName')}
                id="surreal-level-levelName"
                name="levelName"
                data-cy="levelName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealLevel.displayName')}
                id="surreal-level-displayName"
                name="displayName"
                data-cy="displayName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="surreal-level-child"
                name="child"
                data-cy="child"
                label={translate('surrealManagerApp.surrealLevel.child')}
                type="select"
              >
                <option value="" key="0" />
                {surrealLevels
                  ? surrealLevels.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-level-surrealMainExperience"
                name="surrealMainExperience"
                data-cy="surrealMainExperience"
                label={translate('surrealManagerApp.surrealLevel.surrealMainExperience')}
                type="select"
              >
                <option value="" key="0" />
                {surrealMainExperiences
                  ? surrealMainExperiences.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/surreal-level" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SurrealLevelUpdate;
