import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EmiratesAgenda from './emirates-agenda';
import EmiratesAgendaDetail from './emirates-agenda-detail';
import EmiratesAgendaUpdate from './emirates-agenda-update';
import EmiratesAgendaDeleteDialog from './emirates-agenda-delete-dialog';

const EmiratesAgendaRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EmiratesAgenda />} />
    <Route path="new" element={<EmiratesAgendaUpdate />} />
    <Route path=":id">
      <Route index element={<EmiratesAgendaDetail />} />
      <Route path="edit" element={<EmiratesAgendaUpdate />} />
      <Route path="delete" element={<EmiratesAgendaDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EmiratesAgendaRoutes;
