import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/data-center">
        <Translate contentKey="global.menu.entities.dataCenter" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/cloud-account-secret">
        <Translate contentKey="global.menu.entities.cloudAccountSecret" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/schedules-for-experience-gpu-fleet">
        <Translate contentKey="global.menu.entities.schedulesForExperienceGpuFleet" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/rules">
        <Translate contentKey="global.menu.entities.rules" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/environment">
        <Translate contentKey="global.menu.entities.environment" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/sub-experience-environment">
        <Translate contentKey="global.menu.entities.subExperienceEnvironment" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/image-file">
        <Translate contentKey="global.menu.entities.imageFile" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/descriptor">
        <Translate contentKey="global.menu.entities.descriptor" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/coupon">
        <Translate contentKey="global.menu.entities.coupon" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/item-to-sell">
        <Translate contentKey="global.menu.entities.itemToSell" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/sale">
        <Translate contentKey="global.menu.entities.sale" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/achievement">
        <Translate contentKey="global.menu.entities.achievement" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/map-point-list">
        <Translate contentKey="global.menu.entities.mapPointList" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/map-point">
        <Translate contentKey="global.menu.entities.mapPoint" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/surreal-sub-experience">
        <Translate contentKey="global.menu.entities.surrealSubExperience" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/surreal-main-experience">
        <Translate contentKey="global.menu.entities.surrealMainExperience" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/surreal-user">
        <Translate contentKey="global.menu.entities.surrealUser" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/surreal-experience-user">
        <Translate contentKey="global.menu.entities.surrealExperienceUser" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/event-role">
        <Translate contentKey="global.menu.entities.eventRole" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/player">
        <Translate contentKey="global.menu.entities.player" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/leaderboard">
        <Translate contentKey="global.menu.entities.leaderboard" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/agenda">
        <Translate contentKey="global.menu.entities.agenda" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/appointment">
        <Translate contentKey="global.menu.entities.appointment" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/surreal-level">
        <Translate contentKey="global.menu.entities.surrealLevel" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/channel">
        <Translate contentKey="global.menu.entities.channel" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/video-stream">
        <Translate contentKey="global.menu.entities.videoStream" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/smart-screen">
        <Translate contentKey="global.menu.entities.smartScreen" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/mini-game">
        <Translate contentKey="global.menu.entities.miniGame" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/surreal-file">
        <Translate contentKey="global.menu.entities.surrealFile" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/avatar">
        <Translate contentKey="global.menu.entities.avatar" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/appearance">
        <Translate contentKey="global.menu.entities.appearance" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/event-appearance">
        <Translate contentKey="global.menu.entities.eventAppearance" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/event-integration">
        <Translate contentKey="global.menu.entities.eventIntegration" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/address">
        <Translate contentKey="global.menu.entities.address" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/client-organization">
        <Translate contentKey="global.menu.entities.clientOrganization" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/instance">
        <Translate contentKey="global.menu.entities.instance" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/instance-report">
        <Translate contentKey="global.menu.entities.instanceReport" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/event-log">
        <Translate contentKey="global.menu.entities.eventLog" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/event-type">
        <Translate contentKey="global.menu.entities.eventType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/owner">
        <Translate contentKey="global.menu.entities.owner" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/emirates-agenda">
        <Translate contentKey="global.menu.entities.emiratesAgenda" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/invitees">
        <Translate contentKey="global.menu.entities.invitees" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
