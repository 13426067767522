import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './instance.reducer';

export const InstanceDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const instanceEntity = useAppSelector(state => state.instance.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="instanceDetailsHeading">
          <Translate contentKey="surrealManagerApp.instance.detail.title">Instance</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.id}</dd>
          <dt>
            <span id="osType">
              <Translate contentKey="surrealManagerApp.instance.osType">Os Type</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.osType}</dd>
          <dt>
            <span id="currentState">
              <Translate contentKey="surrealManagerApp.instance.currentState">Current State</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.currentState}</dd>
          <dt>
            <span id="experienceName">
              <Translate contentKey="surrealManagerApp.instance.experienceName">Experience Name</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.experienceName}</dd>
          <dt>
            <span id="resourceGroupName">
              <Translate contentKey="surrealManagerApp.instance.resourceGroupName">Resource Group Name</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.resourceGroupName}</dd>
          <dt>
            <span id="regionName">
              <Translate contentKey="surrealManagerApp.instance.regionName">Region Name</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.regionName}</dd>
          <dt>
            <span id="vmName">
              <Translate contentKey="surrealManagerApp.instance.vmName">Vm Name</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.vmName}</dd>
          <dt>
            <span id="vmId">
              <Translate contentKey="surrealManagerApp.instance.vmId">Vm Id</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.vmId}</dd>
          <dt>
            <span id="friendlyVmId">
              <Translate contentKey="surrealManagerApp.instance.friendlyVmId">Friendly Vm Id</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.friendlyVmId}</dd>
          <dt>
            <span id="ipAddressToUse">
              <Translate contentKey="surrealManagerApp.instance.ipAddressToUse">Ip Address To Use</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.ipAddressToUse}</dd>
          <dt>
            <span id="vmPort">
              <Translate contentKey="surrealManagerApp.instance.vmPort">Vm Port</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.vmPort}</dd>
          <dt>
            <span id="vmSize">
              <Translate contentKey="surrealManagerApp.instance.vmSize">Vm Size</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.vmSize}</dd>
          <dt>
            <span id="diskLocation">
              <Translate contentKey="surrealManagerApp.instance.diskLocation">Disk Location</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.diskLocation}</dd>
          <dt>
            <span id="imageName">
              <Translate contentKey="surrealManagerApp.instance.imageName">Image Name</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.imageName}</dd>
          <dt>
            <span id="surrealGameName">
              <Translate contentKey="surrealManagerApp.instance.surrealGameName">Surreal Game Name</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.surrealGameName}</dd>
          <dt>
            <span id="vmUserName">
              <Translate contentKey="surrealManagerApp.instance.vmUserName">Vm User Name</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.vmUserName}</dd>
          <dt>
            <span id="vmUserPassword">
              <Translate contentKey="surrealManagerApp.instance.vmUserPassword">Vm User Password</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.vmUserPassword}</dd>
          <dt>
            <span id="sshUserName">
              <Translate contentKey="surrealManagerApp.instance.sshUserName">Ssh User Name</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.sshUserName}</dd>
          <dt>
            <span id="sshPassword">
              <Translate contentKey="surrealManagerApp.instance.sshPassword">Ssh Password</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.sshPassword}</dd>
          <dt>
            <span id="sshPublicKey">
              <Translate contentKey="surrealManagerApp.instance.sshPublicKey">Ssh Public Key</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.sshPublicKey}</dd>
          <dt>
            <span id="preProcessingCommands">
              <Translate contentKey="surrealManagerApp.instance.preProcessingCommands">Pre Processing Commands</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.preProcessingCommands}</dd>
          <dt>
            <span id="postProcessingCommands">
              <Translate contentKey="surrealManagerApp.instance.postProcessingCommands">Post Processing Commands</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.postProcessingCommands}</dd>
          <dt>
            <span id="signalingServerIP">
              <Translate contentKey="surrealManagerApp.instance.signalingServerIP">Signaling Server IP</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.signalingServerIP}</dd>
          <dt>
            <span id="signalingServerPort">
              <Translate contentKey="surrealManagerApp.instance.signalingServerPort">Signaling Server Port</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.signalingServerPort}</dd>
          <dt>
            <span id="publicIP">
              <Translate contentKey="surrealManagerApp.instance.publicIP">Public IP</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.publicIP}</dd>
          <dt>
            <span id="privateIP">
              <Translate contentKey="surrealManagerApp.instance.privateIP">Private IP</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.privateIP}</dd>
          <dt>
            <span id="dnsNameURL">
              <Translate contentKey="surrealManagerApp.instance.dnsNameURL">Dns Name URL</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.dnsNameURL}</dd>
          <dt>
            <span id="streamerConnected">
              <Translate contentKey="surrealManagerApp.instance.streamerConnected">Streamer Connected</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.streamerConnected ? 'true' : 'false'}</dd>
          <dt>
            <span id="userConnected">
              <Translate contentKey="surrealManagerApp.instance.userConnected">User Connected</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.userConnected ? 'true' : 'false'}</dd>
          <dt>
            <span id="hasError">
              <Translate contentKey="surrealManagerApp.instance.hasError">Has Error</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.hasError ? 'true' : 'false'}</dd>
          <dt>
            <span id="connectedClientIP">
              <Translate contentKey="surrealManagerApp.instance.connectedClientIP">Connected Client IP</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.connectedClientIP}</dd>
          <dt>
            <span id="connectedUserId">
              <Translate contentKey="surrealManagerApp.instance.connectedUserId">Connected User Id</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.connectedUserId}</dd>
          <dt>
            <span id="currentConnectionStarted">
              <Translate contentKey="surrealManagerApp.instance.currentConnectionStarted">Current Connection Started</Translate>
            </span>
          </dt>
          <dd>
            {instanceEntity.currentConnectionStarted ? (
              <TextFormat value={instanceEntity.currentConnectionStarted} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastConnectionEnded">
              <Translate contentKey="surrealManagerApp.instance.lastConnectionEnded">Last Connection Ended</Translate>
            </span>
          </dt>
          <dd>
            {instanceEntity.lastConnectionEnded ? (
              <TextFormat value={instanceEntity.lastConnectionEnded} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lastContactFromStreamer">
              <Translate contentKey="surrealManagerApp.instance.lastContactFromStreamer">Last Contact From Streamer</Translate>
            </span>
          </dt>
          <dd>
            {instanceEntity.lastContactFromStreamer ? (
              <TextFormat value={instanceEntity.lastContactFromStreamer} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="isTemplate">
              <Translate contentKey="surrealManagerApp.instance.isTemplate">Is Template</Translate>
            </span>
          </dt>
          <dd>{instanceEntity.isTemplate ? 'true' : 'false'}</dd>
        </dl>
        <Button tag={Link} to="/instance" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/instance/${instanceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default InstanceDetail;
