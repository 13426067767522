import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MapPoint from './map-point';
import MapPointDetail from './map-point-detail';
import MapPointUpdate from './map-point-update';
import MapPointDeleteDialog from './map-point-delete-dialog';

const MapPointRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MapPoint />} />
    <Route path="new" element={<MapPointUpdate />} />
    <Route path=":id">
      <Route index element={<MapPointDetail />} />
      <Route path="edit" element={<MapPointUpdate />} />
      <Route path="delete" element={<MapPointDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MapPointRoutes;
