import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './surreal-experience-user.reducer';

export const SurrealExperienceUserDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const surrealExperienceUserEntity = useAppSelector(state => state.surrealExperienceUser.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="surrealExperienceUserDetailsHeading">
          <Translate contentKey="surrealManagerApp.surrealExperienceUser.detail.title">SurrealExperienceUser</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{surrealExperienceUserEntity.id}</dd>
          <dt>
            <span id="canEnterEventTime">
              <Translate contentKey="surrealManagerApp.surrealExperienceUser.canEnterEventTime">Can Enter Event Time</Translate>
            </span>
          </dt>
          <dd>
            {surrealExperienceUserEntity.canEnterEventTime ? (
              <TextFormat value={surrealExperienceUserEntity.canEnterEventTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="mustLeaveEventTime">
              <Translate contentKey="surrealManagerApp.surrealExperienceUser.mustLeaveEventTime">Must Leave Event Time</Translate>
            </span>
          </dt>
          <dd>
            {surrealExperienceUserEntity.mustLeaveEventTime ? (
              <TextFormat value={surrealExperienceUserEntity.mustLeaveEventTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="isBanned">
              <Translate contentKey="surrealManagerApp.surrealExperienceUser.isBanned">Is Banned</Translate>
            </span>
          </dt>
          <dd>{surrealExperienceUserEntity.isBanned ? 'true' : 'false'}</dd>
          <dt>
            <span id="isActiveStatus">
              <Translate contentKey="surrealManagerApp.surrealExperienceUser.isActiveStatus">Is Active Status</Translate>
            </span>
          </dt>
          <dd>{surrealExperienceUserEntity.isActiveStatus ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealExperienceUser.agenda">Agenda</Translate>
          </dt>
          <dd>{surrealExperienceUserEntity.agenda ? surrealExperienceUserEntity.agenda.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealExperienceUser.surrealSubExperience">Surreal Sub Experience</Translate>
          </dt>
          <dd>{surrealExperienceUserEntity.surrealSubExperience ? surrealExperienceUserEntity.surrealSubExperience.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealExperienceUser.player">Player</Translate>
          </dt>
          <dd>{surrealExperienceUserEntity.player ? surrealExperienceUserEntity.player.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.surrealExperienceUser.surrealUser">Surreal User</Translate>
          </dt>
          <dd>{surrealExperienceUserEntity.surrealUser ? surrealExperienceUserEntity.surrealUser.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/surreal-experience-user" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/surreal-experience-user/${surrealExperienceUserEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SurrealExperienceUserDetail;
