import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Environment from './environment';
import EnvironmentDetail from './environment-detail';
import EnvironmentUpdate from './environment-update';
import EnvironmentDeleteDialog from './environment-delete-dialog';

const EnvironmentRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Environment />} />
    <Route path="new" element={<EnvironmentUpdate />} />
    <Route path=":id">
      <Route index element={<EnvironmentDetail />} />
      <Route path="edit" element={<EnvironmentUpdate />} />
      <Route path="delete" element={<EnvironmentDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EnvironmentRoutes;
