import { IAppointment } from 'app/shared/model/appointment.model';
import { ISurrealExperienceUser } from 'app/shared/model/surreal-experience-user.model';

export interface IAgenda {
  id?: number;
  agendaName?: string | null;
  appointments?: IAppointment[] | null;
  surrealExperienceUser?: ISurrealExperienceUser | null;
}

export const defaultValue: Readonly<IAgenda> = {};
