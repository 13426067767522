import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';
import { ISubExperienceEnvironment } from 'app/shared/model/sub-experience-environment.model';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';

export interface IEnvironment {
  id?: number;
  frontEndName?: string;
  frontEndLocationURL?: string;
  apiGatewayName?: string | null;
  apiGatewayURL?: string | null;
  webSocketURL?: string | null;
  webSocketPort?: number | null;
  useMatchmaker?: boolean;
  matchmakerServerIP?: string | null;
  matchmakerServerPort?: string | null;
  turnServerURL?: string | null;
  turnServerPort?: number | null;
  stunServerURL?: string | null;
  stunServerPort?: number | null;
  surrealMainExperience?: ISurrealMainExperience | null;
  isPartOf?: ISubExperienceEnvironment | null;
  deploys?: ISurrealSubExperience | null;
}

export const defaultValue: Readonly<IEnvironment> = {
  useMatchmaker: false,
};
