import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Rules from './rules';
import RulesDetail from './rules-detail';
import RulesUpdate from './rules-update';
import RulesDeleteDialog from './rules-delete-dialog';

const RulesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Rules />} />
    <Route path="new" element={<RulesUpdate />} />
    <Route path=":id">
      <Route index element={<RulesDetail />} />
      <Route path="edit" element={<RulesUpdate />} />
      <Route path="delete" element={<RulesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RulesRoutes;
