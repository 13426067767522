import dayjs from 'dayjs';
import { IImageFile } from 'app/shared/model/image-file.model';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';
import { IChannel } from 'app/shared/model/channel.model';

export interface IDescriptor {
  id?: number;
  descriptorId?: string | null;
  begins?: string | null;
  ends?: string | null;
  title?: string | null;
  description?: string | null;
  subdomain?: string | null;
  defaultSpace?: string | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  warningColor?: string | null;
  dangerColor?: string | null;
  googleFontUrl?: string | null;
  channelCSSContentType?: string | null;
  channelCSS?: string | null;
  mapId?: number | null;
  imageFile?: IImageFile | null;
  surrealSubExperience?: ISurrealSubExperience | null;
  channel?: IChannel | null;
}

export const defaultValue: Readonly<IDescriptor> = {};
