import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IMapPointList } from 'app/shared/model/map-point-list.model';
import { getEntities as getMapPointLists } from 'app/entities/map-point-list/map-point-list.reducer';
import { IMapPoint } from 'app/shared/model/map-point.model';
import { Access } from 'app/shared/model/enumerations/access.model';
import { getEntity, updateEntity, createEntity, reset } from './map-point.reducer';

export const MapPointUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const mapPointLists = useAppSelector(state => state.mapPointList.entities);
  const mapPointEntity = useAppSelector(state => state.mapPoint.entity);
  const loading = useAppSelector(state => state.mapPoint.loading);
  const updating = useAppSelector(state => state.mapPoint.updating);
  const updateSuccess = useAppSelector(state => state.mapPoint.updateSuccess);
  const accessValues = Object.keys(Access);

  const handleClose = () => {
    navigate('/map-point');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getMapPointLists({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...mapPointEntity,
      ...values,
      mapPointList: mapPointLists.find(it => it.id.toString() === values.mapPointList.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          pointAccess: 'OPEN',
          ...mapPointEntity,
          mapPointList: mapPointEntity?.mapPointList?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.mapPoint.home.createOrEditLabel" data-cy="MapPointCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.mapPoint.home.createOrEditLabel">Create or edit a MapPoint</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="map-point-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.mapPoint.pointName')}
                id="map-point-pointName"
                name="pointName"
                data-cy="pointName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.mapPoint.pointURL')}
                id="map-point-pointURL"
                name="pointURL"
                data-cy="pointURL"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.mapPoint.pointAccess')}
                id="map-point-pointAccess"
                name="pointAccess"
                data-cy="pointAccess"
                type="select"
              >
                {accessValues.map(access => (
                  <option value={access} key={access}>
                    {translate('surrealManagerApp.Access.' + access)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('surrealManagerApp.mapPoint.pointActive')}
                id="map-point-pointActive"
                name="pointActive"
                data-cy="pointActive"
                check
                type="checkbox"
              />
              <ValidatedField
                id="map-point-mapPointList"
                name="mapPointList"
                data-cy="mapPointList"
                label={translate('surrealManagerApp.mapPoint.mapPointList')}
                type="select"
              >
                <option value="" key="0" />
                {mapPointLists
                  ? mapPointLists.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/map-point" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MapPointUpdate;
