export enum CurrentState {
  AVAILABLE = 'AVAILABLE',

  DEALLOCATED = 'DEALLOCATED',

  DEALLOCATING = 'DEALLOCATING',

  STOPPING = 'STOPPING',

  STOPPED = 'STOPPED',

  STARTING = 'STARTING',

  RUNNING = 'RUNNING',

  SUSPENDED = 'SUSPENDED',

  UNKNOWN = 'UNKNOWN',

  TERMINATED = 'TERMINATED',

  FAILED = 'FAILED',
}
