import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { openFile, byteSize, Translate, TextFormat, getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities, reset } from './descriptor.reducer';

export const Descriptor = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );
  const [sorting, setSorting] = useState(false);

  const descriptorList = useAppSelector(state => state.descriptor.entities);
  const loading = useAppSelector(state => state.descriptor.loading);
  const links = useAppSelector(state => state.descriptor.links);
  const updateSuccess = useAppSelector(state => state.descriptor.updateSuccess);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const resetAll = () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      resetAll();
    }
  }, [updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    dispatch(reset());
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setSorting(true);
  };

  const handleSyncList = () => {
    resetAll();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="descriptor-heading" data-cy="DescriptorHeading">
        <Translate contentKey="surrealManagerApp.descriptor.home.title">Descriptors</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="surrealManagerApp.descriptor.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/descriptor/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="surrealManagerApp.descriptor.home.createLabel">Create new Descriptor</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          dataLength={descriptorList ? descriptorList.length : 0}
          next={handleLoadMore}
          hasMore={paginationState.activePage - 1 < links.next}
          loader={<div className="loader">Loading ...</div>}
        >
          {descriptorList && descriptorList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="surrealManagerApp.descriptor.id">ID</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                  </th>
                  <th className="hand" onClick={sort('descriptorId')}>
                    <Translate contentKey="surrealManagerApp.descriptor.descriptorId">Descriptor Id</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('descriptorId')} />
                  </th>
                  <th className="hand" onClick={sort('begins')}>
                    <Translate contentKey="surrealManagerApp.descriptor.begins">Begins</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('begins')} />
                  </th>
                  <th className="hand" onClick={sort('ends')}>
                    <Translate contentKey="surrealManagerApp.descriptor.ends">Ends</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('ends')} />
                  </th>
                  <th className="hand" onClick={sort('title')}>
                    <Translate contentKey="surrealManagerApp.descriptor.title">Title</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('title')} />
                  </th>
                  <th className="hand" onClick={sort('description')}>
                    <Translate contentKey="surrealManagerApp.descriptor.description">Description</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('description')} />
                  </th>
                  <th className="hand" onClick={sort('subdomain')}>
                    <Translate contentKey="surrealManagerApp.descriptor.subdomain">Subdomain</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('subdomain')} />
                  </th>
                  <th className="hand" onClick={sort('defaultSpace')}>
                    <Translate contentKey="surrealManagerApp.descriptor.defaultSpace">Default Space</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('defaultSpace')} />
                  </th>
                  <th className="hand" onClick={sort('primaryColor')}>
                    <Translate contentKey="surrealManagerApp.descriptor.primaryColor">Primary Color</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('primaryColor')} />
                  </th>
                  <th className="hand" onClick={sort('secondaryColor')}>
                    <Translate contentKey="surrealManagerApp.descriptor.secondaryColor">Secondary Color</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('secondaryColor')} />
                  </th>
                  <th className="hand" onClick={sort('warningColor')}>
                    <Translate contentKey="surrealManagerApp.descriptor.warningColor">Warning Color</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('warningColor')} />
                  </th>
                  <th className="hand" onClick={sort('dangerColor')}>
                    <Translate contentKey="surrealManagerApp.descriptor.dangerColor">Danger Color</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('dangerColor')} />
                  </th>
                  <th className="hand" onClick={sort('googleFontUrl')}>
                    <Translate contentKey="surrealManagerApp.descriptor.googleFontUrl">Google Font Url</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('googleFontUrl')} />
                  </th>
                  <th className="hand" onClick={sort('channelCSS')}>
                    <Translate contentKey="surrealManagerApp.descriptor.channelCSS">Channel CSS</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('channelCSS')} />
                  </th>
                  <th className="hand" onClick={sort('mapId')}>
                    <Translate contentKey="surrealManagerApp.descriptor.mapId">Map Id</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('mapId')} />
                  </th>
                  <th>
                    <Translate contentKey="surrealManagerApp.descriptor.imageFile">Image File</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {descriptorList.map((descriptor, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/descriptor/${descriptor.id}`} color="link" size="sm">
                        {descriptor.id}
                      </Button>
                    </td>
                    <td>{descriptor.descriptorId}</td>
                    <td>{descriptor.begins ? <TextFormat type="date" value={descriptor.begins} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{descriptor.ends ? <TextFormat type="date" value={descriptor.ends} format={APP_DATE_FORMAT} /> : null}</td>
                    <td>{descriptor.title}</td>
                    <td>{descriptor.description}</td>
                    <td>{descriptor.subdomain}</td>
                    <td>{descriptor.defaultSpace}</td>
                    <td>{descriptor.primaryColor}</td>
                    <td>{descriptor.secondaryColor}</td>
                    <td>{descriptor.warningColor}</td>
                    <td>{descriptor.dangerColor}</td>
                    <td>{descriptor.googleFontUrl}</td>
                    <td>
                      {descriptor.channelCSS ? (
                        <div>
                          {descriptor.channelCSSContentType ? (
                            <a onClick={openFile(descriptor.channelCSSContentType, descriptor.channelCSS)}>
                              <Translate contentKey="entity.action.open">Open</Translate>
                              &nbsp;
                            </a>
                          ) : null}
                          <span>
                            {descriptor.channelCSSContentType}, {byteSize(descriptor.channelCSS)}
                          </span>
                        </div>
                      ) : null}
                    </td>
                    <td>{descriptor.mapId}</td>
                    <td>
                      {descriptor.imageFile ? <Link to={`/image-file/${descriptor.imageFile.id}`}>{descriptor.imageFile.id}</Link> : ''}
                    </td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`/descriptor/${descriptor.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`/descriptor/${descriptor.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`/descriptor/${descriptor.id}/delete`} color="danger" size="sm" data-cy="entityDeleteButton">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="surrealManagerApp.descriptor.home.notFound">No Descriptors found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Descriptor;
