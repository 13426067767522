import { IAppearance } from 'app/shared/model/appearance.model';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';

export interface IEventAppearance {
  id?: number;
  eventAppearanceName?: string;
  appearance?: IAppearance | null;
  surrealSubExperience?: ISurrealSubExperience | null;
}

export const defaultValue: Readonly<IEventAppearance> = {};
