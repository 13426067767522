import dayjs from 'dayjs';
import { IEventAppearance } from 'app/shared/model/event-appearance.model';
import { IEventIntegration } from 'app/shared/model/event-integration.model';
import { IDescriptor } from 'app/shared/model/descriptor.model';
import { IMapPointList } from 'app/shared/model/map-point-list.model';
import { ISubExperienceEnvironment } from 'app/shared/model/sub-experience-environment.model';
import { IItemToSell } from 'app/shared/model/item-to-sell.model';
import { IEnvironment } from 'app/shared/model/environment.model';
import { IEmiratesAgenda } from 'app/shared/model/emirates-agenda.model';
import { ISurrealExperienceUser } from 'app/shared/model/surreal-experience-user.model';
import { IAppointment } from 'app/shared/model/appointment.model';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';

export interface ISurrealSubExperience {
  id?: number;
  experienceName?: string;
  startAt?: string | null;
  endAt?: string | null;
  isRecurring?: boolean | null;
  useMatchmaker?: boolean;
  matchmakerServerIP?: string | null;
  matchmakerServerPort?: string | null;
  turnServerURL?: string | null;
  turnServerPort?: number | null;
  stunServerURL?: string | null;
  stunServerPort?: number | null;
  isActive?: boolean | null;
  eventAppearance?: IEventAppearance | null;
  eventIntegration?: IEventIntegration | null;
  descriptor?: IDescriptor | null;
  mapPointList?: IMapPointList | null;
  subExperienceEnvironment?: ISubExperienceEnvironment | null;
  itemToSells?: IItemToSell[] | null;
  deployedWiths?: IEnvironment[] | null;
  emiratesAgenda?: IEmiratesAgenda[] | null;
  surrealExperienceUser?: ISurrealExperienceUser | null;
  appointment?: IAppointment | null;
  makesUp?: ISurrealMainExperience | null;
}

export const defaultValue: Readonly<ISurrealSubExperience> = {
  isRecurring: false,
  useMatchmaker: false,
  isActive: false,
};
