import { IEnvironment } from 'app/shared/model/environment.model';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';

export interface ISubExperienceEnvironment {
  id?: number;
  gameImageName?: string;
  gameImageLocationURL?: string;
  has?: IEnvironment | null;
  surrealSubExperience?: ISurrealSubExperience | null;
}

export const defaultValue: Readonly<ISubExperienceEnvironment> = {};
