import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EventAppearance from './event-appearance';
import EventAppearanceDetail from './event-appearance-detail';
import EventAppearanceUpdate from './event-appearance-update';
import EventAppearanceDeleteDialog from './event-appearance-delete-dialog';

const EventAppearanceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EventAppearance />} />
    <Route path="new" element={<EventAppearanceUpdate />} />
    <Route path=":id">
      <Route index element={<EventAppearanceDetail />} />
      <Route path="edit" element={<EventAppearanceUpdate />} />
      <Route path="delete" element={<EventAppearanceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EventAppearanceRoutes;
