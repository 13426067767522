import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SurrealSubExperience from './surreal-sub-experience';
import SurrealSubExperienceDetail from './surreal-sub-experience-detail';
import SurrealSubExperienceUpdate from './surreal-sub-experience-update';
import SurrealSubExperienceDeleteDialog from './surreal-sub-experience-delete-dialog';

const SurrealSubExperienceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SurrealSubExperience />} />
    <Route path="new" element={<SurrealSubExperienceUpdate />} />
    <Route path=":id">
      <Route index element={<SurrealSubExperienceDetail />} />
      <Route path="edit" element={<SurrealSubExperienceUpdate />} />
      <Route path="delete" element={<SurrealSubExperienceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SurrealSubExperienceRoutes;
