import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import VideoStream from './video-stream';
import VideoStreamDetail from './video-stream-detail';
import VideoStreamUpdate from './video-stream-update';
import VideoStreamDeleteDialog from './video-stream-delete-dialog';

const VideoStreamRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<VideoStream />} />
    <Route path="new" element={<VideoStreamUpdate />} />
    <Route path=":id">
      <Route index element={<VideoStreamDetail />} />
      <Route path="edit" element={<VideoStreamUpdate />} />
      <Route path="delete" element={<VideoStreamDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default VideoStreamRoutes;
