import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SurrealExperienceUser from './surreal-experience-user';
import SurrealExperienceUserDetail from './surreal-experience-user-detail';
import SurrealExperienceUserUpdate from './surreal-experience-user-update';
import SurrealExperienceUserDeleteDialog from './surreal-experience-user-delete-dialog';

const SurrealExperienceUserRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SurrealExperienceUser />} />
    <Route path="new" element={<SurrealExperienceUserUpdate />} />
    <Route path=":id">
      <Route index element={<SurrealExperienceUserDetail />} />
      <Route path="edit" element={<SurrealExperienceUserUpdate />} />
      <Route path="delete" element={<SurrealExperienceUserDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SurrealExperienceUserRoutes;
