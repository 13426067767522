import { IOwner } from 'app/shared/model/owner.model';
import { ISurrealUser } from 'app/shared/model/surreal-user.model';

export interface IAddress {
  id?: number;
  address?: string;
  city?: string;
  state?: string | null;
  country?: string | null;
  mailingAddressOwner?: IOwner | null;
  officialAddressOwner?: IOwner | null;
  surrealUser?: ISurrealUser | null;
}

export const defaultValue: Readonly<IAddress> = {};
