import dayjs from 'dayjs';
import { IOwner } from 'app/shared/model/owner.model';
import { ComputeSystem } from 'app/shared/model/enumerations/compute-system.model';

export interface IInstanceReport {
  id?: number;
  serverProvider?: keyof typeof ComputeSystem | null;
  instanceID?: string;
  startedRunningAt?: string | null;
  stoppedRunningAt?: string | null;
  owner?: IOwner | null;
}

export const defaultValue: Readonly<IInstanceReport> = {};
