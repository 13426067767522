import dataCenter from 'app/entities/data-center/data-center.reducer';
import cloudAccountSecret from 'app/entities/cloud-account-secret/cloud-account-secret.reducer';
import schedulesForExperienceGPUFleet from 'app/entities/schedules-for-experience-gpu-fleet/schedules-for-experience-gpu-fleet.reducer';
import rules from 'app/entities/rules/rules.reducer';
import environment from 'app/entities/environment/environment.reducer';
import subExperienceEnvironment from 'app/entities/sub-experience-environment/sub-experience-environment.reducer';
import imageFile from 'app/entities/image-file/image-file.reducer';
import descriptor from 'app/entities/descriptor/descriptor.reducer';
import coupon from 'app/entities/coupon/coupon.reducer';
import itemToSell from 'app/entities/item-to-sell/item-to-sell.reducer';
import sale from 'app/entities/sale/sale.reducer';
import achievement from 'app/entities/achievement/achievement.reducer';
import mapPointList from 'app/entities/map-point-list/map-point-list.reducer';
import mapPoint from 'app/entities/map-point/map-point.reducer';
import surrealSubExperience from 'app/entities/surreal-sub-experience/surreal-sub-experience.reducer';
import surrealMainExperience from 'app/entities/surreal-main-experience/surreal-main-experience.reducer';
import surrealUser from 'app/entities/surreal-user/surreal-user.reducer';
import surrealExperienceUser from 'app/entities/surreal-experience-user/surreal-experience-user.reducer';
import eventRole from 'app/entities/event-role/event-role.reducer';
import player from 'app/entities/player/player.reducer';
import leaderboard from 'app/entities/leaderboard/leaderboard.reducer';
import agenda from 'app/entities/agenda/agenda.reducer';
import appointment from 'app/entities/appointment/appointment.reducer';
import surrealLevel from 'app/entities/surreal-level/surreal-level.reducer';
import channel from 'app/entities/channel/channel.reducer';
import videoStream from 'app/entities/video-stream/video-stream.reducer';
import smartScreen from 'app/entities/smart-screen/smart-screen.reducer';
import miniGame from 'app/entities/mini-game/mini-game.reducer';
import surrealFile from 'app/entities/surreal-file/surreal-file.reducer';
import avatar from 'app/entities/avatar/avatar.reducer';
import appearance from 'app/entities/appearance/appearance.reducer';
import eventAppearance from 'app/entities/event-appearance/event-appearance.reducer';
import eventIntegration from 'app/entities/event-integration/event-integration.reducer';
import address from 'app/entities/address/address.reducer';
import clientOrganization from 'app/entities/client-organization/client-organization.reducer';
import instance from 'app/entities/instance/instance.reducer';
import instanceReport from 'app/entities/instance-report/instance-report.reducer';
import eventLog from 'app/entities/event-log/event-log.reducer';
import eventType from 'app/entities/event-type/event-type.reducer';
import owner from 'app/entities/owner/owner.reducer';
import emiratesAgenda from 'app/entities/emirates-agenda/emirates-agenda.reducer';
import invitees from 'app/entities/invitees/invitees.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  dataCenter,
  cloudAccountSecret,
  schedulesForExperienceGPUFleet,
  rules,
  environment,
  subExperienceEnvironment,
  imageFile,
  descriptor,
  coupon,
  itemToSell,
  sale,
  achievement,
  mapPointList,
  mapPoint,
  surrealSubExperience,
  surrealMainExperience,
  surrealUser,
  surrealExperienceUser,
  eventRole,
  player,
  leaderboard,
  agenda,
  appointment,
  surrealLevel,
  channel,
  videoStream,
  smartScreen,
  miniGame,
  surrealFile,
  avatar,
  appearance,
  eventAppearance,
  eventIntegration,
  address,
  clientOrganization,
  instance,
  instanceReport,
  eventLog,
  eventType,
  owner,
  emiratesAgenda,
  invitees,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
