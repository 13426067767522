import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SurrealLevel from './surreal-level';
import SurrealLevelDetail from './surreal-level-detail';
import SurrealLevelUpdate from './surreal-level-update';
import SurrealLevelDeleteDialog from './surreal-level-delete-dialog';

const SurrealLevelRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SurrealLevel />} />
    <Route path="new" element={<SurrealLevelUpdate />} />
    <Route path=":id">
      <Route index element={<SurrealLevelDetail />} />
      <Route path="edit" element={<SurrealLevelUpdate />} />
      <Route path="delete" element={<SurrealLevelDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SurrealLevelRoutes;
