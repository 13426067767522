import dayjs from 'dayjs';
import { IEventType } from 'app/shared/model/event-type.model';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';

export interface IEventLog {
  id?: number;
  sessionId?: string;
  userId?: string;
  beforeState?: string | null;
  afterState?: string | null;
  eventSpecificData?: string | null;
  eventTimeStamp?: string;
  eventType?: IEventType | null;
  surrealMainExperience?: ISurrealMainExperience | null;
}

export const defaultValue: Readonly<IEventLog> = {};
