import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IEventAppearance } from 'app/shared/model/event-appearance.model';
import { getEntities as getEventAppearances } from 'app/entities/event-appearance/event-appearance.reducer';
import { IEventIntegration } from 'app/shared/model/event-integration.model';
import { getEntities as getEventIntegrations } from 'app/entities/event-integration/event-integration.reducer';
import { IDescriptor } from 'app/shared/model/descriptor.model';
import { getEntities as getDescriptors } from 'app/entities/descriptor/descriptor.reducer';
import { IMapPointList } from 'app/shared/model/map-point-list.model';
import { getEntities as getMapPointLists } from 'app/entities/map-point-list/map-point-list.reducer';
import { ISubExperienceEnvironment } from 'app/shared/model/sub-experience-environment.model';
import { getEntities as getSubExperienceEnvironments } from 'app/entities/sub-experience-environment/sub-experience-environment.reducer';
import { ISurrealExperienceUser } from 'app/shared/model/surreal-experience-user.model';
import { getEntities as getSurrealExperienceUsers } from 'app/entities/surreal-experience-user/surreal-experience-user.reducer';
import { IAppointment } from 'app/shared/model/appointment.model';
import { getEntities as getAppointments } from 'app/entities/appointment/appointment.reducer';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';
import { getEntities as getSurrealMainExperiences } from 'app/entities/surreal-main-experience/surreal-main-experience.reducer';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';
import { getEntity, updateEntity, createEntity, reset } from './surreal-sub-experience.reducer';

export const SurrealSubExperienceUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const eventAppearances = useAppSelector(state => state.eventAppearance.entities);
  const eventIntegrations = useAppSelector(state => state.eventIntegration.entities);
  const descriptors = useAppSelector(state => state.descriptor.entities);
  const mapPointLists = useAppSelector(state => state.mapPointList.entities);
  const subExperienceEnvironments = useAppSelector(state => state.subExperienceEnvironment.entities);
  const surrealExperienceUsers = useAppSelector(state => state.surrealExperienceUser.entities);
  const appointments = useAppSelector(state => state.appointment.entities);
  const surrealMainExperiences = useAppSelector(state => state.surrealMainExperience.entities);
  const surrealSubExperienceEntity = useAppSelector(state => state.surrealSubExperience.entity);
  const loading = useAppSelector(state => state.surrealSubExperience.loading);
  const updating = useAppSelector(state => state.surrealSubExperience.updating);
  const updateSuccess = useAppSelector(state => state.surrealSubExperience.updateSuccess);

  const handleClose = () => {
    navigate('/surreal-sub-experience');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getEventAppearances({}));
    dispatch(getEventIntegrations({}));
    dispatch(getDescriptors({}));
    dispatch(getMapPointLists({}));
    dispatch(getSubExperienceEnvironments({}));
    dispatch(getSurrealExperienceUsers({}));
    dispatch(getAppointments({}));
    dispatch(getSurrealMainExperiences({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.startAt = convertDateTimeToServer(values.startAt);
    values.endAt = convertDateTimeToServer(values.endAt);

    const entity = {
      ...surrealSubExperienceEntity,
      ...values,
      eventAppearance: eventAppearances.find(it => it.id.toString() === values.eventAppearance.toString()),
      eventIntegration: eventIntegrations.find(it => it.id.toString() === values.eventIntegration.toString()),
      descriptor: descriptors.find(it => it.id.toString() === values.descriptor.toString()),
      mapPointList: mapPointLists.find(it => it.id.toString() === values.mapPointList.toString()),
      subExperienceEnvironment: subExperienceEnvironments.find(it => it.id.toString() === values.subExperienceEnvironment.toString()),
      makesUp: surrealMainExperiences.find(it => it.id.toString() === values.makesUp.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          startAt: displayDefaultDateTime(),
          endAt: displayDefaultDateTime(),
        }
      : {
          ...surrealSubExperienceEntity,
          startAt: convertDateTimeFromServer(surrealSubExperienceEntity.startAt),
          endAt: convertDateTimeFromServer(surrealSubExperienceEntity.endAt),
          eventAppearance: surrealSubExperienceEntity?.eventAppearance?.id,
          eventIntegration: surrealSubExperienceEntity?.eventIntegration?.id,
          descriptor: surrealSubExperienceEntity?.descriptor?.id,
          mapPointList: surrealSubExperienceEntity?.mapPointList?.id,
          subExperienceEnvironment: surrealSubExperienceEntity?.subExperienceEnvironment?.id,
          makesUp: surrealSubExperienceEntity?.makesUp?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.surrealSubExperience.home.createOrEditLabel" data-cy="SurrealSubExperienceCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.surrealSubExperience.home.createOrEditLabel">
              Create or edit a SurrealSubExperience
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="surreal-sub-experience-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.experienceName')}
                id="surreal-sub-experience-experienceName"
                name="experienceName"
                data-cy="experienceName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.startAt')}
                id="surreal-sub-experience-startAt"
                name="startAt"
                data-cy="startAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.endAt')}
                id="surreal-sub-experience-endAt"
                name="endAt"
                data-cy="endAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.isRecurring')}
                id="surreal-sub-experience-isRecurring"
                name="isRecurring"
                data-cy="isRecurring"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.useMatchmaker')}
                id="surreal-sub-experience-useMatchmaker"
                name="useMatchmaker"
                data-cy="useMatchmaker"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.matchmakerServerIP')}
                id="surreal-sub-experience-matchmakerServerIP"
                name="matchmakerServerIP"
                data-cy="matchmakerServerIP"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.matchmakerServerPort')}
                id="surreal-sub-experience-matchmakerServerPort"
                name="matchmakerServerPort"
                data-cy="matchmakerServerPort"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.turnServerURL')}
                id="surreal-sub-experience-turnServerURL"
                name="turnServerURL"
                data-cy="turnServerURL"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.turnServerPort')}
                id="surreal-sub-experience-turnServerPort"
                name="turnServerPort"
                data-cy="turnServerPort"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.stunServerURL')}
                id="surreal-sub-experience-stunServerURL"
                name="stunServerURL"
                data-cy="stunServerURL"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.stunServerPort')}
                id="surreal-sub-experience-stunServerPort"
                name="stunServerPort"
                data-cy="stunServerPort"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealSubExperience.isActive')}
                id="surreal-sub-experience-isActive"
                name="isActive"
                data-cy="isActive"
                check
                type="checkbox"
              />
              <ValidatedField
                id="surreal-sub-experience-eventAppearance"
                name="eventAppearance"
                data-cy="eventAppearance"
                label={translate('surrealManagerApp.surrealSubExperience.eventAppearance')}
                type="select"
              >
                <option value="" key="0" />
                {eventAppearances
                  ? eventAppearances.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-sub-experience-eventIntegration"
                name="eventIntegration"
                data-cy="eventIntegration"
                label={translate('surrealManagerApp.surrealSubExperience.eventIntegration')}
                type="select"
              >
                <option value="" key="0" />
                {eventIntegrations
                  ? eventIntegrations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-sub-experience-descriptor"
                name="descriptor"
                data-cy="descriptor"
                label={translate('surrealManagerApp.surrealSubExperience.descriptor')}
                type="select"
              >
                <option value="" key="0" />
                {descriptors
                  ? descriptors.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-sub-experience-mapPointList"
                name="mapPointList"
                data-cy="mapPointList"
                label={translate('surrealManagerApp.surrealSubExperience.mapPointList')}
                type="select"
              >
                <option value="" key="0" />
                {mapPointLists
                  ? mapPointLists.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-sub-experience-subExperienceEnvironment"
                name="subExperienceEnvironment"
                data-cy="subExperienceEnvironment"
                label={translate('surrealManagerApp.surrealSubExperience.subExperienceEnvironment')}
                type="select"
              >
                <option value="" key="0" />
                {subExperienceEnvironments
                  ? subExperienceEnvironments.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-sub-experience-makesUp"
                name="makesUp"
                data-cy="makesUp"
                label={translate('surrealManagerApp.surrealSubExperience.makesUp')}
                type="select"
              >
                <option value="" key="0" />
                {surrealMainExperiences
                  ? surrealMainExperiences.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/surreal-sub-experience" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SurrealSubExperienceUpdate;
