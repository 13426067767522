import { IPlayer } from 'app/shared/model/player.model';

export interface IAchievement {
  id?: number;
  name?: string;
  locationURL?: string;
  player?: IPlayer | null;
}

export const defaultValue: Readonly<IAchievement> = {};
