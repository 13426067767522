import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IAddress } from 'app/shared/model/address.model';
import { IEmiratesAgenda } from 'app/shared/model/emirates-agenda.model';
import { IInvitees } from 'app/shared/model/invitees.model';
import { IAppointment } from 'app/shared/model/appointment.model';
import { ISurrealFile } from 'app/shared/model/surreal-file.model';
import { ISurrealExperienceUser } from 'app/shared/model/surreal-experience-user.model';
import { IClientOrganization } from 'app/shared/model/client-organization.model';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';

export interface ISurrealUser {
  id?: number;
  userPhotoContentType?: string | null;
  userPhoto?: string | null;
  title?: string | null;
  confirmed?: boolean | null;
  lastLogin?: string | null;
  lastUpdated?: string | null;
  user?: IUser | null;
  addresses?: IAddress[] | null;
  emiratesAgenda?: IEmiratesAgenda[] | null;
  inviteesUsers?: IInvitees[] | null;
  appointment?: IAppointment | null;
  surrealFile?: ISurrealFile | null;
  surrealExperienceUser?: ISurrealExperienceUser | null;
  clientOrganization?: IClientOrganization | null;
  surrealMainExperience?: ISurrealMainExperience | null;
}

export const defaultValue: Readonly<ISurrealUser> = {
  confirmed: false,
};
