import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MapPointList from './map-point-list';
import MapPointListDetail from './map-point-list-detail';
import MapPointListUpdate from './map-point-list-update';
import MapPointListDeleteDialog from './map-point-list-delete-dialog';

const MapPointListRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MapPointList />} />
    <Route path="new" element={<MapPointListUpdate />} />
    <Route path=":id">
      <Route index element={<MapPointListDetail />} />
      <Route path="edit" element={<MapPointListUpdate />} />
      <Route path="delete" element={<MapPointListDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MapPointListRoutes;
