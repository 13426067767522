import dayjs from 'dayjs';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';
import { ISurrealLevel } from 'app/shared/model/surreal-level.model';
import { ISurrealUser } from 'app/shared/model/surreal-user.model';
import { IAgenda } from 'app/shared/model/agenda.model';
import { Frequency } from 'app/shared/model/enumerations/frequency.model';

export interface IAppointment {
  id?: number;
  title?: string;
  location?: string;
  surrealExperienceGroupName?: string | null;
  inviterPerson?: number;
  invitedPerson?: number;
  startDateTime?: string;
  endDateTime?: string;
  recurring?: boolean;
  frequency?: keyof typeof Frequency;
  surrealSubExperience?: ISurrealSubExperience | null;
  surrealLevel?: ISurrealLevel | null;
  surrealUser?: ISurrealUser | null;
  agenda?: IAgenda | null;
}

export const defaultValue: Readonly<IAppointment> = {
  recurring: false,
};
