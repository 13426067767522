export enum PaymentType {
  CREDITCARD = 'CREDITCARD',

  DEBITCARD = 'DEBITCARD',

  ACH = 'ACH',

  PAYPAL = 'PAYPAL',

  CRYPTO = 'CRYPTO',

  COUPON = 'COUPON',

  STORECURRENCY = 'STORECURRENCY',
}
