import { IPlayer } from 'app/shared/model/player.model';
import { AvatarType } from 'app/shared/model/enumerations/avatar-type.model';

export interface IAvatar {
  id?: number;
  name?: string;
  avatarType?: keyof typeof AvatarType;
  avatarFileContentType?: string;
  avatarFile?: string;
  player?: IPlayer | null;
}

export const defaultValue: Readonly<IAvatar> = {};
