import { IDescriptor } from 'app/shared/model/descriptor.model';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';

export interface IChannel {
  id?: number;
  name?: string;
  descriptor?: IDescriptor | null;
  surrealMainExperience?: ISurrealMainExperience | null;
}

export const defaultValue: Readonly<IChannel> = {};
