import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DataCenter from './data-center';
import DataCenterDetail from './data-center-detail';
import DataCenterUpdate from './data-center-update';
import DataCenterDeleteDialog from './data-center-delete-dialog';

const DataCenterRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DataCenter />} />
    <Route path="new" element={<DataCenterUpdate />} />
    <Route path=":id">
      <Route index element={<DataCenterDetail />} />
      <Route path="edit" element={<DataCenterUpdate />} />
      <Route path="delete" element={<DataCenterDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DataCenterRoutes;
