import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SurrealUser from './surreal-user';
import SurrealUserDetail from './surreal-user-detail';
import SurrealUserUpdate from './surreal-user-update';
import SurrealUserDeleteDialog from './surreal-user-delete-dialog';

const SurrealUserRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SurrealUser />} />
    <Route path="new" element={<SurrealUserUpdate />} />
    <Route path=":id">
      <Route index element={<SurrealUserDetail />} />
      <Route path="edit" element={<SurrealUserUpdate />} />
      <Route path="delete" element={<SurrealUserDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SurrealUserRoutes;
