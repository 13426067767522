import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ISmartScreen } from 'app/shared/model/smart-screen.model';
import { getEntities as getSmartScreens } from 'app/entities/smart-screen/smart-screen.reducer';
import { IVideoStream } from 'app/shared/model/video-stream.model';
import { getEntity, updateEntity, createEntity, reset } from './video-stream.reducer';

export const VideoStreamUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const smartScreens = useAppSelector(state => state.smartScreen.entities);
  const videoStreamEntity = useAppSelector(state => state.videoStream.entity);
  const loading = useAppSelector(state => state.videoStream.loading);
  const updating = useAppSelector(state => state.videoStream.updating);
  const updateSuccess = useAppSelector(state => state.videoStream.updateSuccess);

  const handleClose = () => {
    navigate('/video-stream');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getSmartScreens({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...videoStreamEntity,
      ...values,
      smartScreen: smartScreens.find(it => it.id.toString() === values.smartScreen.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...videoStreamEntity,
          smartScreen: videoStreamEntity?.smartScreen?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.videoStream.home.createOrEditLabel" data-cy="VideoStreamCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.videoStream.home.createOrEditLabel">Create or edit a VideoStream</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="video-stream-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.videoStream.name')}
                id="video-stream-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.videoStream.locationUrl')}
                id="video-stream-locationUrl"
                name="locationUrl"
                data-cy="locationUrl"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="video-stream-smartScreen"
                name="smartScreen"
                data-cy="smartScreen"
                label={translate('surrealManagerApp.videoStream.smartScreen')}
                type="select"
              >
                <option value="" key="0" />
                {smartScreens
                  ? smartScreens.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/video-stream" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default VideoStreamUpdate;
