import { ISurrealExperienceUser } from 'app/shared/model/surreal-experience-user.model';
import { EventUserRole } from 'app/shared/model/enumerations/event-user-role.model';

export interface IEventRole {
  id?: number;
  eventRoleType?: keyof typeof EventUserRole;
  eventUser?: ISurrealExperienceUser | null;
}

export const defaultValue: Readonly<IEventRole> = {};
