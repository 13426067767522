import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './rules.reducer';

export const RulesDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const rulesEntity = useAppSelector(state => state.rules.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="rulesDetailsHeading">
          <Translate contentKey="surrealManagerApp.rules.detail.title">Rules</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{rulesEntity.id}</dd>
          <dt>
            <span id="minimumInstances">
              <Translate contentKey="surrealManagerApp.rules.minimumInstances">Minimum Instances</Translate>
            </span>
          </dt>
          <dd>{rulesEntity.minimumInstances}</dd>
          <dt>
            <span id="maximumInstances">
              <Translate contentKey="surrealManagerApp.rules.maximumInstances">Maximum Instances</Translate>
            </span>
          </dt>
          <dd>{rulesEntity.maximumInstances}</dd>
          <dt>
            <span id="stepUpIncrement">
              <Translate contentKey="surrealManagerApp.rules.stepUpIncrement">Step Up Increment</Translate>
            </span>
          </dt>
          <dd>{rulesEntity.stepUpIncrement}</dd>
          <dt>
            <span id="stepDownIncrement">
              <Translate contentKey="surrealManagerApp.rules.stepDownIncrement">Step Down Increment</Translate>
            </span>
          </dt>
          <dd>{rulesEntity.stepDownIncrement}</dd>
          <dt>
            <span id="bufferInstancesToKeep">
              <Translate contentKey="surrealManagerApp.rules.bufferInstancesToKeep">Buffer Instances To Keep</Translate>
            </span>
          </dt>
          <dd>{rulesEntity.bufferInstancesToKeep}</dd>
          <dt>
            <span id="startRunningAt">
              <Translate contentKey="surrealManagerApp.rules.startRunningAt">Start Running At</Translate>
            </span>
          </dt>
          <dd>
            {rulesEntity.startRunningAt ? <TextFormat value={rulesEntity.startRunningAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="stopRunningAt">
              <Translate contentKey="surrealManagerApp.rules.stopRunningAt">Stop Running At</Translate>
            </span>
          </dt>
          <dd>
            {rulesEntity.stopRunningAt ? <TextFormat value={rulesEntity.stopRunningAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
        </dl>
        <Button tag={Link} to="/rules" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/rules/${rulesEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RulesDetail;
