import { IMapPointList } from 'app/shared/model/map-point-list.model';
import { Access } from 'app/shared/model/enumerations/access.model';

export interface IMapPoint {
  id?: number;
  pointName?: string;
  pointURL?: string;
  pointAccess?: keyof typeof Access;
  pointActive?: boolean;
  mapPointList?: IMapPointList | null;
}

export const defaultValue: Readonly<IMapPoint> = {
  pointActive: false,
};
