import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './image-file.reducer';

export const ImageFileDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const imageFileEntity = useAppSelector(state => state.imageFile.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="imageFileDetailsHeading">
          <Translate contentKey="surrealManagerApp.imageFile.detail.title">ImageFile</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{imageFileEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="surrealManagerApp.imageFile.name">Name</Translate>
            </span>
          </dt>
          <dd>{imageFileEntity.name}</dd>
          <dt>
            <span id="fileData">
              <Translate contentKey="surrealManagerApp.imageFile.fileData">File Data</Translate>
            </span>
          </dt>
          <dd>
            {imageFileEntity.fileData ? (
              <div>
                {imageFileEntity.fileDataContentType ? (
                  <a onClick={openFile(imageFileEntity.fileDataContentType, imageFileEntity.fileData)}>
                    <img
                      src={`data:${imageFileEntity.fileDataContentType};base64,${imageFileEntity.fileData}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {imageFileEntity.fileDataContentType}, {byteSize(imageFileEntity.fileData)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="fileLocationURL">
              <Translate contentKey="surrealManagerApp.imageFile.fileLocationURL">File Location URL</Translate>
            </span>
          </dt>
          <dd>{imageFileEntity.fileLocationURL}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.imageFile.itemToSell">Item To Sell</Translate>
          </dt>
          <dd>{imageFileEntity.itemToSell ? imageFileEntity.itemToSell.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/image-file" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/image-file/${imageFileEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ImageFileDetail;
