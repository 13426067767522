import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './client-organization.reducer';

export const ClientOrganizationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const clientOrganizationEntity = useAppSelector(state => state.clientOrganization.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="clientOrganizationDetailsHeading">
          <Translate contentKey="surrealManagerApp.clientOrganization.detail.title">ClientOrganization</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{clientOrganizationEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="surrealManagerApp.clientOrganization.name">Name</Translate>
            </span>
          </dt>
          <dd>{clientOrganizationEntity.name}</dd>
          <dt>
            <span id="authType">
              <Translate contentKey="surrealManagerApp.clientOrganization.authType">Auth Type</Translate>
            </span>
          </dt>
          <dd>{clientOrganizationEntity.authType}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.clientOrganization.owner">Owner</Translate>
          </dt>
          <dd>{clientOrganizationEntity.owner ? clientOrganizationEntity.owner.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/client-organization" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/client-organization/${clientOrganizationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ClientOrganizationDetail;
