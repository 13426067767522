import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './avatar.reducer';

export const AvatarDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const avatarEntity = useAppSelector(state => state.avatar.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="avatarDetailsHeading">
          <Translate contentKey="surrealManagerApp.avatar.detail.title">Avatar</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{avatarEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="surrealManagerApp.avatar.name">Name</Translate>
            </span>
          </dt>
          <dd>{avatarEntity.name}</dd>
          <dt>
            <span id="avatarType">
              <Translate contentKey="surrealManagerApp.avatar.avatarType">Avatar Type</Translate>
            </span>
          </dt>
          <dd>{avatarEntity.avatarType}</dd>
          <dt>
            <span id="avatarFile">
              <Translate contentKey="surrealManagerApp.avatar.avatarFile">Avatar File</Translate>
            </span>
          </dt>
          <dd>
            {avatarEntity.avatarFile ? (
              <div>
                {avatarEntity.avatarFileContentType ? (
                  <a onClick={openFile(avatarEntity.avatarFileContentType, avatarEntity.avatarFile)}>
                    <Translate contentKey="entity.action.open">Open</Translate>&nbsp;
                  </a>
                ) : null}
                <span>
                  {avatarEntity.avatarFileContentType}, {byteSize(avatarEntity.avatarFile)}
                </span>
              </div>
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/avatar" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/avatar/${avatarEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AvatarDetail;
