import dayjs from 'dayjs';
import { IEventLog } from 'app/shared/model/event-log.model';

export interface IEventType {
  id?: number;
  clientName?: string;
  description?: string;
  eventTypeCreationTimestamp?: string;
  eventName?: string;
  eventLog?: IEventLog | null;
}

export const defaultValue: Readonly<IEventType> = {};
