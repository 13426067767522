import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Invitees from './invitees';
import InviteesDetail from './invitees-detail';
import InviteesUpdate from './invitees-update';
import InviteesDeleteDialog from './invitees-delete-dialog';

const InviteesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Invitees />} />
    <Route path="new" element={<InviteesUpdate />} />
    <Route path=":id">
      <Route index element={<InviteesDetail />} />
      <Route path="edit" element={<InviteesUpdate />} />
      <Route path="delete" element={<InviteesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InviteesRoutes;
