import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IEventType } from 'app/shared/model/event-type.model';
import { getEntities as getEventTypes } from 'app/entities/event-type/event-type.reducer';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';
import { getEntities as getSurrealMainExperiences } from 'app/entities/surreal-main-experience/surreal-main-experience.reducer';
import { IEventLog } from 'app/shared/model/event-log.model';
import { getEntity, updateEntity, createEntity, reset } from './event-log.reducer';

export const EventLogUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const eventTypes = useAppSelector(state => state.eventType.entities);
  const surrealMainExperiences = useAppSelector(state => state.surrealMainExperience.entities);
  const eventLogEntity = useAppSelector(state => state.eventLog.entity);
  const loading = useAppSelector(state => state.eventLog.loading);
  const updating = useAppSelector(state => state.eventLog.updating);
  const updateSuccess = useAppSelector(state => state.eventLog.updateSuccess);

  const handleClose = () => {
    navigate('/event-log');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getEventTypes({}));
    dispatch(getSurrealMainExperiences({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.eventTimeStamp = convertDateTimeToServer(values.eventTimeStamp);

    const entity = {
      ...eventLogEntity,
      ...values,
      eventType: eventTypes.find(it => it.id.toString() === values.eventType.toString()),
      surrealMainExperience: surrealMainExperiences.find(it => it.id.toString() === values.surrealMainExperience.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          eventTimeStamp: displayDefaultDateTime(),
        }
      : {
          ...eventLogEntity,
          eventTimeStamp: convertDateTimeFromServer(eventLogEntity.eventTimeStamp),
          eventType: eventLogEntity?.eventType?.id,
          surrealMainExperience: eventLogEntity?.surrealMainExperience?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.eventLog.home.createOrEditLabel" data-cy="EventLogCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.eventLog.home.createOrEditLabel">Create or edit a EventLog</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="event-log-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.eventLog.sessionId')}
                id="event-log-sessionId"
                name="sessionId"
                data-cy="sessionId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.eventLog.userId')}
                id="event-log-userId"
                name="userId"
                data-cy="userId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.eventLog.beforeState')}
                id="event-log-beforeState"
                name="beforeState"
                data-cy="beforeState"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.eventLog.afterState')}
                id="event-log-afterState"
                name="afterState"
                data-cy="afterState"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.eventLog.eventSpecificData')}
                id="event-log-eventSpecificData"
                name="eventSpecificData"
                data-cy="eventSpecificData"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.eventLog.eventTimeStamp')}
                id="event-log-eventTimeStamp"
                name="eventTimeStamp"
                data-cy="eventTimeStamp"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="event-log-eventType"
                name="eventType"
                data-cy="eventType"
                label={translate('surrealManagerApp.eventLog.eventType')}
                type="select"
              >
                <option value="" key="0" />
                {eventTypes
                  ? eventTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="event-log-surrealMainExperience"
                name="surrealMainExperience"
                data-cy="surrealMainExperience"
                label={translate('surrealManagerApp.eventLog.surrealMainExperience')}
                type="select"
              >
                <option value="" key="0" />
                {surrealMainExperiences
                  ? surrealMainExperiences.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/event-log" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EventLogUpdate;
