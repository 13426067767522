import { ISurrealUser } from 'app/shared/model/surreal-user.model';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';
import { MimeType } from 'app/shared/model/enumerations/mime-type.model';

export interface ISurrealFile {
  id?: number;
  displayName?: string;
  mimeType?: keyof typeof MimeType;
  fileDataContentType?: string;
  fileData?: string;
  surrealUser?: ISurrealUser | null;
  surrealMainExperience?: ISurrealMainExperience | null;
}

export const defaultValue: Readonly<ISurrealFile> = {};
