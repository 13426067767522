import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SurrealMainExperience from './surreal-main-experience';
import SurrealMainExperienceDetail from './surreal-main-experience-detail';
import SurrealMainExperienceUpdate from './surreal-main-experience-update';
import SurrealMainExperienceDeleteDialog from './surreal-main-experience-delete-dialog';

const SurrealMainExperienceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SurrealMainExperience />} />
    <Route path="new" element={<SurrealMainExperienceUpdate />} />
    <Route path=":id">
      <Route index element={<SurrealMainExperienceDetail />} />
      <Route path="edit" element={<SurrealMainExperienceUpdate />} />
      <Route path="delete" element={<SurrealMainExperienceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SurrealMainExperienceRoutes;
