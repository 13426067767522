import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IEmiratesAgenda } from 'app/shared/model/emirates-agenda.model';
import { getEntities as getEmiratesAgenda } from 'app/entities/emirates-agenda/emirates-agenda.reducer';
import { ISurrealUser } from 'app/shared/model/surreal-user.model';
import { getEntities as getSurrealUsers } from 'app/entities/surreal-user/surreal-user.reducer';
import { IInvitees } from 'app/shared/model/invitees.model';
import { InviteesStatus } from 'app/shared/model/enumerations/invitees-status.model';
import { getEntity, updateEntity, createEntity, reset } from './invitees.reducer';

export const InviteesUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const emiratesAgenda = useAppSelector(state => state.emiratesAgenda.entities);
  const surrealUsers = useAppSelector(state => state.surrealUser.entities);
  const inviteesEntity = useAppSelector(state => state.invitees.entity);
  const loading = useAppSelector(state => state.invitees.loading);
  const updating = useAppSelector(state => state.invitees.updating);
  const updateSuccess = useAppSelector(state => state.invitees.updateSuccess);
  const inviteesStatusValues = Object.keys(InviteesStatus);

  const handleClose = () => {
    navigate('/invitees');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getEmiratesAgenda({}));
    dispatch(getSurrealUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...inviteesEntity,
      ...values,
      emiratesAgenda: emiratesAgenda.find(it => it.id.toString() === values.emiratesAgenda.toString()),
      surrealUsers: surrealUsers.find(it => it.id.toString() === values.surrealUsers.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          status: 'PENDING',
          ...inviteesEntity,
          emiratesAgenda: inviteesEntity?.emiratesAgenda?.id,
          surrealUsers: inviteesEntity?.surrealUsers?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.invitees.home.createOrEditLabel" data-cy="InviteesCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.invitees.home.createOrEditLabel">Create or edit a Invitees</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="invitees-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.invitees.status')}
                id="invitees-status"
                name="status"
                data-cy="status"
                type="select"
              >
                {inviteesStatusValues.map(inviteesStatus => (
                  <option value={inviteesStatus} key={inviteesStatus}>
                    {translate('surrealManagerApp.InviteesStatus.' + inviteesStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                id="invitees-emiratesAgenda"
                name="emiratesAgenda"
                data-cy="emiratesAgenda"
                label={translate('surrealManagerApp.invitees.emiratesAgenda')}
                type="select"
              >
                <option value="" key="0" />
                {emiratesAgenda
                  ? emiratesAgenda.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="invitees-surrealUsers"
                name="surrealUsers"
                data-cy="surrealUsers"
                label={translate('surrealManagerApp.invitees.surrealUsers')}
                type="select"
              >
                <option value="" key="0" />
                {surrealUsers
                  ? surrealUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/invitees" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InviteesUpdate;
