import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './item-to-sell.reducer';

export const ItemToSellDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const itemToSellEntity = useAppSelector(state => state.itemToSell.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="itemToSellDetailsHeading">
          <Translate contentKey="surrealManagerApp.itemToSell.detail.title">ItemToSell</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{itemToSellEntity.id}</dd>
          <dt>
            <span id="itemName">
              <Translate contentKey="surrealManagerApp.itemToSell.itemName">Item Name</Translate>
            </span>
          </dt>
          <dd>{itemToSellEntity.itemName}</dd>
          <dt>
            <span id="itemPrice">
              <Translate contentKey="surrealManagerApp.itemToSell.itemPrice">Item Price</Translate>
            </span>
          </dt>
          <dd>{itemToSellEntity.itemPrice}</dd>
          <dt>
            <span id="itemSalePrice">
              <Translate contentKey="surrealManagerApp.itemToSell.itemSalePrice">Item Sale Price</Translate>
            </span>
          </dt>
          <dd>{itemToSellEntity.itemSalePrice}</dd>
          <dt>
            <span id="itemStoreLink">
              <Translate contentKey="surrealManagerApp.itemToSell.itemStoreLink">Item Store Link</Translate>
            </span>
          </dt>
          <dd>{itemToSellEntity.itemStoreLink}</dd>
          <dt>
            <span id="itemActive">
              <Translate contentKey="surrealManagerApp.itemToSell.itemActive">Item Active</Translate>
            </span>
          </dt>
          <dd>{itemToSellEntity.itemActive ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.itemToSell.sale">Sale</Translate>
          </dt>
          <dd>{itemToSellEntity.sale ? itemToSellEntity.sale.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.itemToSell.surrealSubExperience">Surreal Sub Experience</Translate>
          </dt>
          <dd>{itemToSellEntity.surrealSubExperience ? itemToSellEntity.surrealSubExperience.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/item-to-sell" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/item-to-sell/${itemToSellEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ItemToSellDetail;
