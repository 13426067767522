export enum MimeType {
  IMAGE_PNG = 'IMAGE_PNG',

  IMAGE_JPG = 'IMAGE_JPG',

  IMAGE_GIF = 'IMAGE_GIF',

  RAW_JSON = 'RAW_JSON',

  TEXT_HTML = 'TEXT_HTML',

  TEXT_ASCII = 'TEXT_ASCII',
}
