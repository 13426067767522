import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DataCenter from './data-center';
import CloudAccountSecret from './cloud-account-secret';
import SchedulesForExperienceGPUFleet from './schedules-for-experience-gpu-fleet';
import Rules from './rules';
import Environment from './environment';
import SubExperienceEnvironment from './sub-experience-environment';
import ImageFile from './image-file';
import Descriptor from './descriptor';
import Coupon from './coupon';
import ItemToSell from './item-to-sell';
import Sale from './sale';
import Achievement from './achievement';
import MapPointList from './map-point-list';
import MapPoint from './map-point';
import SurrealSubExperience from './surreal-sub-experience';
import SurrealMainExperience from './surreal-main-experience';
import SurrealUser from './surreal-user';
import SurrealExperienceUser from './surreal-experience-user';
import EventRole from './event-role';
import Player from './player';
import Leaderboard from './leaderboard';
import Agenda from './agenda';
import Appointment from './appointment';
import SurrealLevel from './surreal-level';
import Channel from './channel';
import VideoStream from './video-stream';
import SmartScreen from './smart-screen';
import MiniGame from './mini-game';
import SurrealFile from './surreal-file';
import Avatar from './avatar';
import Appearance from './appearance';
import EventAppearance from './event-appearance';
import EventIntegration from './event-integration';
import Address from './address';
import ClientOrganization from './client-organization';
import Instance from './instance';
import InstanceReport from './instance-report';
import EventLog from './event-log';
import EventType from './event-type';
import Owner from './owner';
import EmiratesAgenda from './emirates-agenda';
import Invitees from './invitees';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="data-center/*" element={<DataCenter />} />
        <Route path="cloud-account-secret/*" element={<CloudAccountSecret />} />
        <Route path="schedules-for-experience-gpu-fleet/*" element={<SchedulesForExperienceGPUFleet />} />
        <Route path="rules/*" element={<Rules />} />
        <Route path="environment/*" element={<Environment />} />
        <Route path="sub-experience-environment/*" element={<SubExperienceEnvironment />} />
        <Route path="image-file/*" element={<ImageFile />} />
        <Route path="descriptor/*" element={<Descriptor />} />
        <Route path="coupon/*" element={<Coupon />} />
        <Route path="item-to-sell/*" element={<ItemToSell />} />
        <Route path="sale/*" element={<Sale />} />
        <Route path="achievement/*" element={<Achievement />} />
        <Route path="map-point-list/*" element={<MapPointList />} />
        <Route path="map-point/*" element={<MapPoint />} />
        <Route path="surreal-sub-experience/*" element={<SurrealSubExperience />} />
        <Route path="surreal-main-experience/*" element={<SurrealMainExperience />} />
        <Route path="surreal-user/*" element={<SurrealUser />} />
        <Route path="surreal-experience-user/*" element={<SurrealExperienceUser />} />
        <Route path="event-role/*" element={<EventRole />} />
        <Route path="player/*" element={<Player />} />
        <Route path="leaderboard/*" element={<Leaderboard />} />
        <Route path="agenda/*" element={<Agenda />} />
        <Route path="appointment/*" element={<Appointment />} />
        <Route path="surreal-level/*" element={<SurrealLevel />} />
        <Route path="channel/*" element={<Channel />} />
        <Route path="video-stream/*" element={<VideoStream />} />
        <Route path="smart-screen/*" element={<SmartScreen />} />
        <Route path="mini-game/*" element={<MiniGame />} />
        <Route path="surreal-file/*" element={<SurrealFile />} />
        <Route path="avatar/*" element={<Avatar />} />
        <Route path="appearance/*" element={<Appearance />} />
        <Route path="event-appearance/*" element={<EventAppearance />} />
        <Route path="event-integration/*" element={<EventIntegration />} />
        <Route path="address/*" element={<Address />} />
        <Route path="client-organization/*" element={<ClientOrganization />} />
        <Route path="instance/*" element={<Instance />} />
        <Route path="instance-report/*" element={<InstanceReport />} />
        <Route path="event-log/*" element={<EventLog />} />
        <Route path="event-type/*" element={<EventType />} />
        <Route path="owner/*" element={<Owner />} />
        <Route path="emirates-agenda/*" element={<EmiratesAgenda />} />
        <Route path="invitees/*" element={<Invitees />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
