import { IPlayer } from 'app/shared/model/player.model';
import { IMiniGame } from 'app/shared/model/mini-game.model';

export interface ILeaderboard {
  id?: number;
  name?: string;
  highestScore?: number;
  lowestScore?: number;
  players?: IPlayer[] | null;
  miniGame?: IMiniGame | null;
}

export const defaultValue: Readonly<ILeaderboard> = {};
