import { IAchievement } from 'app/shared/model/achievement.model';
import { IAvatar } from 'app/shared/model/avatar.model';
import { ISurrealExperienceUser } from 'app/shared/model/surreal-experience-user.model';
import { IMiniGame } from 'app/shared/model/mini-game.model';
import { ILeaderboard } from 'app/shared/model/leaderboard.model';

export interface IPlayer {
  id?: number;
  gameScore?: number | null;
  achievement?: IAchievement | null;
  avatar?: IAvatar | null;
  surrealExperienceUser?: ISurrealExperienceUser | null;
  miniGame?: IMiniGame | null;
  leaderboard?: ILeaderboard | null;
}

export const defaultValue: Readonly<IPlayer> = {};
