import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './appointment.reducer';

export const AppointmentDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const appointmentEntity = useAppSelector(state => state.appointment.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="appointmentDetailsHeading">
          <Translate contentKey="surrealManagerApp.appointment.detail.title">Appointment</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="surrealManagerApp.appointment.title">Title</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.title}</dd>
          <dt>
            <span id="location">
              <Translate contentKey="surrealManagerApp.appointment.location">Location</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.location}</dd>
          <dt>
            <span id="surrealExperienceGroupName">
              <Translate contentKey="surrealManagerApp.appointment.surrealExperienceGroupName">Surreal Experience Group Name</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.surrealExperienceGroupName}</dd>
          <dt>
            <span id="inviterPerson">
              <Translate contentKey="surrealManagerApp.appointment.inviterPerson">Inviter Person</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.inviterPerson}</dd>
          <dt>
            <span id="invitedPerson">
              <Translate contentKey="surrealManagerApp.appointment.invitedPerson">Invited Person</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.invitedPerson}</dd>
          <dt>
            <span id="startDateTime">
              <Translate contentKey="surrealManagerApp.appointment.startDateTime">Start Date Time</Translate>
            </span>
          </dt>
          <dd>
            {appointmentEntity.startDateTime ? (
              <TextFormat value={appointmentEntity.startDateTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="endDateTime">
              <Translate contentKey="surrealManagerApp.appointment.endDateTime">End Date Time</Translate>
            </span>
          </dt>
          <dd>
            {appointmentEntity.endDateTime ? (
              <TextFormat value={appointmentEntity.endDateTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="recurring">
              <Translate contentKey="surrealManagerApp.appointment.recurring">Recurring</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.recurring ? 'true' : 'false'}</dd>
          <dt>
            <span id="frequency">
              <Translate contentKey="surrealManagerApp.appointment.frequency">Frequency</Translate>
            </span>
          </dt>
          <dd>{appointmentEntity.frequency}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.appointment.surrealSubExperience">Surreal Sub Experience</Translate>
          </dt>
          <dd>{appointmentEntity.surrealSubExperience ? appointmentEntity.surrealSubExperience.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.appointment.surrealLevel">Surreal Level</Translate>
          </dt>
          <dd>{appointmentEntity.surrealLevel ? appointmentEntity.surrealLevel.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.appointment.surrealUser">Surreal User</Translate>
          </dt>
          <dd>{appointmentEntity.surrealUser ? appointmentEntity.surrealUser.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.appointment.agenda">Agenda</Translate>
          </dt>
          <dd>{appointmentEntity.agenda ? appointmentEntity.agenda.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/appointment" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/appointment/${appointmentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AppointmentDetail;
