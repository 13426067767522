export enum AuthType {
  JWT = 'JWT',

  OAUTH = 'OAUTH',

  OPENID = 'OPENID',

  SSO = 'SSO',

  PASSWORD = 'PASSWORD',
}
