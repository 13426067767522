import { IImageFile } from 'app/shared/model/image-file.model';
import { ISale } from 'app/shared/model/sale.model';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';

export interface IItemToSell {
  id?: number;
  itemName?: string | null;
  itemPrice?: number | null;
  itemSalePrice?: number | null;
  itemStoreLink?: string | null;
  itemActive?: boolean | null;
  imageFiles?: IImageFile[] | null;
  sale?: ISale | null;
  surrealSubExperience?: ISurrealSubExperience | null;
}

export const defaultValue: Readonly<IItemToSell> = {
  itemActive: false,
};
