import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm, ValidatedBlobField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/shared/reducers/user-management';
import { IAppointment } from 'app/shared/model/appointment.model';
import { getEntities as getAppointments } from 'app/entities/appointment/appointment.reducer';
import { ISurrealFile } from 'app/shared/model/surreal-file.model';
import { getEntities as getSurrealFiles } from 'app/entities/surreal-file/surreal-file.reducer';
import { ISurrealExperienceUser } from 'app/shared/model/surreal-experience-user.model';
import { getEntities as getSurrealExperienceUsers } from 'app/entities/surreal-experience-user/surreal-experience-user.reducer';
import { IClientOrganization } from 'app/shared/model/client-organization.model';
import { getEntities as getClientOrganizations } from 'app/entities/client-organization/client-organization.reducer';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';
import { getEntities as getSurrealMainExperiences } from 'app/entities/surreal-main-experience/surreal-main-experience.reducer';
import { ISurrealUser } from 'app/shared/model/surreal-user.model';
import { getEntity, updateEntity, createEntity, reset } from './surreal-user.reducer';

export const SurrealUserUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const appointments = useAppSelector(state => state.appointment.entities);
  const surrealFiles = useAppSelector(state => state.surrealFile.entities);
  const surrealExperienceUsers = useAppSelector(state => state.surrealExperienceUser.entities);
  const clientOrganizations = useAppSelector(state => state.clientOrganization.entities);
  const surrealMainExperiences = useAppSelector(state => state.surrealMainExperience.entities);
  const surrealUserEntity = useAppSelector(state => state.surrealUser.entity);
  const loading = useAppSelector(state => state.surrealUser.loading);
  const updating = useAppSelector(state => state.surrealUser.updating);
  const updateSuccess = useAppSelector(state => state.surrealUser.updateSuccess);

  const handleClose = () => {
    navigate('/surreal-user');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
    dispatch(getAppointments({}));
    dispatch(getSurrealFiles({}));
    dispatch(getSurrealExperienceUsers({}));
    dispatch(getClientOrganizations({}));
    dispatch(getSurrealMainExperiences({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.lastLogin = convertDateTimeToServer(values.lastLogin);
    values.lastUpdated = convertDateTimeToServer(values.lastUpdated);

    const entity = {
      ...surrealUserEntity,
      ...values,
      user: users.find(it => it.id.toString() === values.user.toString()),
      clientOrganization: clientOrganizations.find(it => it.id.toString() === values.clientOrganization.toString()),
      surrealMainExperience: surrealMainExperiences.find(it => it.id.toString() === values.surrealMainExperience.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          lastLogin: displayDefaultDateTime(),
          lastUpdated: displayDefaultDateTime(),
        }
      : {
          ...surrealUserEntity,
          lastLogin: convertDateTimeFromServer(surrealUserEntity.lastLogin),
          lastUpdated: convertDateTimeFromServer(surrealUserEntity.lastUpdated),
          user: surrealUserEntity?.user?.id,
          clientOrganization: surrealUserEntity?.clientOrganization?.id,
          surrealMainExperience: surrealUserEntity?.surrealMainExperience?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.surrealUser.home.createOrEditLabel" data-cy="SurrealUserCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.surrealUser.home.createOrEditLabel">Create or edit a SurrealUser</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="surreal-user-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedBlobField
                label={translate('surrealManagerApp.surrealUser.userPhoto')}
                id="surreal-user-userPhoto"
                name="userPhoto"
                data-cy="userPhoto"
                openActionLabel={translate('entity.action.open')}
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealUser.title')}
                id="surreal-user-title"
                name="title"
                data-cy="title"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealUser.confirmed')}
                id="surreal-user-confirmed"
                name="confirmed"
                data-cy="confirmed"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealUser.lastLogin')}
                id="surreal-user-lastLogin"
                name="lastLogin"
                data-cy="lastLogin"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('surrealManagerApp.surrealUser.lastUpdated')}
                id="surreal-user-lastUpdated"
                name="lastUpdated"
                data-cy="lastUpdated"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="surreal-user-user"
                name="user"
                data-cy="user"
                label={translate('surrealManagerApp.surrealUser.user')}
                type="select"
              >
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-user-clientOrganization"
                name="clientOrganization"
                data-cy="clientOrganization"
                label={translate('surrealManagerApp.surrealUser.clientOrganization')}
                type="select"
              >
                <option value="" key="0" />
                {clientOrganizations
                  ? clientOrganizations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="surreal-user-surrealMainExperience"
                name="surrealMainExperience"
                data-cy="surrealMainExperience"
                label={translate('surrealManagerApp.surrealUser.surrealMainExperience')}
                type="select"
              >
                <option value="" key="0" />
                {surrealMainExperiences
                  ? surrealMainExperiences.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/surreal-user" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SurrealUserUpdate;
