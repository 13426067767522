import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EventLog from './event-log';
import EventLogDetail from './event-log-detail';
import EventLogUpdate from './event-log-update';
import EventLogDeleteDialog from './event-log-delete-dialog';

const EventLogRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EventLog />} />
    <Route path="new" element={<EventLogUpdate />} />
    <Route path=":id">
      <Route index element={<EventLogDetail />} />
      <Route path="edit" element={<EventLogUpdate />} />
      <Route path="delete" element={<EventLogDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EventLogRoutes;
