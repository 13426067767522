import { IVideoStream } from 'app/shared/model/video-stream.model';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';

export interface ISmartScreen {
  id?: number;
  name?: string;
  videoStreams?: IVideoStream[] | null;
  surrealMainExperience?: ISurrealMainExperience | null;
}

export const defaultValue: Readonly<ISmartScreen> = {};
