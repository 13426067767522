import { ILeaderboard } from 'app/shared/model/leaderboard.model';
import { IPlayer } from 'app/shared/model/player.model';
import { ISurrealMainExperience } from 'app/shared/model/surreal-main-experience.model';

export interface IMiniGame {
  id?: number;
  name?: string;
  instructions?: string | null;
  notes?: string | null;
  isEnabled?: boolean | null;
  leaderboard?: ILeaderboard | null;
  players?: IPlayer[] | null;
  surrealMainExperience?: ISurrealMainExperience | null;
}

export const defaultValue: Readonly<IMiniGame> = {
  isEnabled: false,
};
