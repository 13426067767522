import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';

export interface IEventIntegration {
  id?: number;
  integrationName?: string;
  accessToken1?: string;
  accessToken2?: string | null;
  secret?: string | null;
  userId?: string | null;
  locationUrl?: string;
  surrealSubExperience?: ISurrealSubExperience | null;
}

export const defaultValue: Readonly<IEventIntegration> = {};
