import { IMapPointList } from 'app/shared/model/map-point-list.model';
import { IEnvironment } from 'app/shared/model/environment.model';
import { ISurrealUser } from 'app/shared/model/surreal-user.model';
import { ISurrealLevel } from 'app/shared/model/surreal-level.model';
import { IChannel } from 'app/shared/model/channel.model';
import { ISmartScreen } from 'app/shared/model/smart-screen.model';
import { IMiniGame } from 'app/shared/model/mini-game.model';
import { ISurrealFile } from 'app/shared/model/surreal-file.model';
import { IEventLog } from 'app/shared/model/event-log.model';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';
import { IOwner } from 'app/shared/model/owner.model';

export interface ISurrealMainExperience {
  id?: number;
  experienceName?: string;
  frontEndURL?: string;
  frontEndPort?: number | null;
  mapPointList?: IMapPointList | null;
  environment?: IEnvironment | null;
  surrealUsers?: ISurrealUser[] | null;
  surrealLevels?: ISurrealLevel[] | null;
  channels?: IChannel[] | null;
  smartScreens?: ISmartScreen[] | null;
  miniGames?: IMiniGame[] | null;
  surrealFiles?: ISurrealFile[] | null;
  eventLogs?: IEventLog[] | null;
  contains?: ISurrealSubExperience[] | null;
  owner?: IOwner | null;
}

export const defaultValue: Readonly<ISurrealMainExperience> = {};
