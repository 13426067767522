import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './appearance.reducer';

export const AppearanceDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const appearanceEntity = useAppSelector(state => state.appearance.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="appearanceDetailsHeading">
          <Translate contentKey="surrealManagerApp.appearance.detail.title">Appearance</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{appearanceEntity.id}</dd>
          <dt>
            <span id="primaryColor">
              <Translate contentKey="surrealManagerApp.appearance.primaryColor">Primary Color</Translate>
            </span>
          </dt>
          <dd>{appearanceEntity.primaryColor}</dd>
          <dt>
            <span id="primaryFont">
              <Translate contentKey="surrealManagerApp.appearance.primaryFont">Primary Font</Translate>
            </span>
          </dt>
          <dd>{appearanceEntity.primaryFont}</dd>
          <dt>
            <span id="primaryStyleCSS">
              <Translate contentKey="surrealManagerApp.appearance.primaryStyleCSS">Primary Style CSS</Translate>
            </span>
          </dt>
          <dd>
            {appearanceEntity.primaryStyleCSS ? (
              <div>
                {appearanceEntity.primaryStyleCSSContentType ? (
                  <a onClick={openFile(appearanceEntity.primaryStyleCSSContentType, appearanceEntity.primaryStyleCSS)}>
                    <Translate contentKey="entity.action.open">Open</Translate>&nbsp;
                  </a>
                ) : null}
                <span>
                  {appearanceEntity.primaryStyleCSSContentType}, {byteSize(appearanceEntity.primaryStyleCSS)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="secondaryColor">
              <Translate contentKey="surrealManagerApp.appearance.secondaryColor">Secondary Color</Translate>
            </span>
          </dt>
          <dd>{appearanceEntity.secondaryColor}</dd>
          <dt>
            <span id="secondaryFont">
              <Translate contentKey="surrealManagerApp.appearance.secondaryFont">Secondary Font</Translate>
            </span>
          </dt>
          <dd>{appearanceEntity.secondaryFont}</dd>
          <dt>
            <span id="secondaryStyleCSS">
              <Translate contentKey="surrealManagerApp.appearance.secondaryStyleCSS">Secondary Style CSS</Translate>
            </span>
          </dt>
          <dd>
            {appearanceEntity.secondaryStyleCSS ? (
              <div>
                {appearanceEntity.secondaryStyleCSSContentType ? (
                  <a onClick={openFile(appearanceEntity.secondaryStyleCSSContentType, appearanceEntity.secondaryStyleCSS)}>
                    <Translate contentKey="entity.action.open">Open</Translate>&nbsp;
                  </a>
                ) : null}
                <span>
                  {appearanceEntity.secondaryStyleCSSContentType}, {byteSize(appearanceEntity.secondaryStyleCSS)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="surrealManagerApp.appearance.loadingScreen">Loading Screen</Translate>
          </dt>
          <dd>{appearanceEntity.loadingScreen ? appearanceEntity.loadingScreen.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.appearance.logo">Logo</Translate>
          </dt>
          <dd>{appearanceEntity.logo ? appearanceEntity.logo.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/appearance" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/appearance/${appearanceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AppearanceDetail;
