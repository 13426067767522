import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Appearance from './appearance';
import AppearanceDetail from './appearance-detail';
import AppearanceUpdate from './appearance-update';
import AppearanceDeleteDialog from './appearance-delete-dialog';

const AppearanceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Appearance />} />
    <Route path="new" element={<AppearanceUpdate />} />
    <Route path=":id">
      <Route index element={<AppearanceDetail />} />
      <Route path="edit" element={<AppearanceUpdate />} />
      <Route path="delete" element={<AppearanceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AppearanceRoutes;
