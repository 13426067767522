import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm, ValidatedBlobField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IImageFile } from 'app/shared/model/image-file.model';
import { getEntities as getImageFiles } from 'app/entities/image-file/image-file.reducer';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';
import { getEntities as getSurrealSubExperiences } from 'app/entities/surreal-sub-experience/surreal-sub-experience.reducer';
import { IChannel } from 'app/shared/model/channel.model';
import { getEntities as getChannels } from 'app/entities/channel/channel.reducer';
import { IDescriptor } from 'app/shared/model/descriptor.model';
import { getEntity, updateEntity, createEntity, reset } from './descriptor.reducer';

export const DescriptorUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const imageFiles = useAppSelector(state => state.imageFile.entities);
  const surrealSubExperiences = useAppSelector(state => state.surrealSubExperience.entities);
  const channels = useAppSelector(state => state.channel.entities);
  const descriptorEntity = useAppSelector(state => state.descriptor.entity);
  const loading = useAppSelector(state => state.descriptor.loading);
  const updating = useAppSelector(state => state.descriptor.updating);
  const updateSuccess = useAppSelector(state => state.descriptor.updateSuccess);

  const handleClose = () => {
    navigate('/descriptor');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getImageFiles({}));
    dispatch(getSurrealSubExperiences({}));
    dispatch(getChannels({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.begins = convertDateTimeToServer(values.begins);
    values.ends = convertDateTimeToServer(values.ends);

    const entity = {
      ...descriptorEntity,
      ...values,
      imageFile: imageFiles.find(it => it.id.toString() === values.imageFile.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          begins: displayDefaultDateTime(),
          ends: displayDefaultDateTime(),
        }
      : {
          ...descriptorEntity,
          begins: convertDateTimeFromServer(descriptorEntity.begins),
          ends: convertDateTimeFromServer(descriptorEntity.ends),
          imageFile: descriptorEntity?.imageFile?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.descriptor.home.createOrEditLabel" data-cy="DescriptorCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.descriptor.home.createOrEditLabel">Create or edit a Descriptor</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="descriptor-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.descriptorId')}
                id="descriptor-descriptorId"
                name="descriptorId"
                data-cy="descriptorId"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.begins')}
                id="descriptor-begins"
                name="begins"
                data-cy="begins"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.ends')}
                id="descriptor-ends"
                name="ends"
                data-cy="ends"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.title')}
                id="descriptor-title"
                name="title"
                data-cy="title"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.description')}
                id="descriptor-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.subdomain')}
                id="descriptor-subdomain"
                name="subdomain"
                data-cy="subdomain"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.defaultSpace')}
                id="descriptor-defaultSpace"
                name="defaultSpace"
                data-cy="defaultSpace"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.primaryColor')}
                id="descriptor-primaryColor"
                name="primaryColor"
                data-cy="primaryColor"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.secondaryColor')}
                id="descriptor-secondaryColor"
                name="secondaryColor"
                data-cy="secondaryColor"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.warningColor')}
                id="descriptor-warningColor"
                name="warningColor"
                data-cy="warningColor"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.dangerColor')}
                id="descriptor-dangerColor"
                name="dangerColor"
                data-cy="dangerColor"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.googleFontUrl')}
                id="descriptor-googleFontUrl"
                name="googleFontUrl"
                data-cy="googleFontUrl"
                type="text"
              />
              <ValidatedBlobField
                label={translate('surrealManagerApp.descriptor.channelCSS')}
                id="descriptor-channelCSS"
                name="channelCSS"
                data-cy="channelCSS"
                openActionLabel={translate('entity.action.open')}
              />
              <ValidatedField
                label={translate('surrealManagerApp.descriptor.mapId')}
                id="descriptor-mapId"
                name="mapId"
                data-cy="mapId"
                type="text"
              />
              <ValidatedField
                id="descriptor-imageFile"
                name="imageFile"
                data-cy="imageFile"
                label={translate('surrealManagerApp.descriptor.imageFile')}
                type="select"
              >
                <option value="" key="0" />
                {imageFiles
                  ? imageFiles.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/descriptor" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DescriptorUpdate;
