import dayjs from 'dayjs';
import { OsType } from 'app/shared/model/enumerations/os-type.model';
import { CurrentState } from 'app/shared/model/enumerations/current-state.model';

export interface IInstance {
  id?: number;
  osType?: keyof typeof OsType;
  currentState?: keyof typeof CurrentState;
  experienceName?: string;
  resourceGroupName?: string;
  regionName?: string;
  vmName?: string;
  vmId?: string;
  friendlyVmId?: string;
  ipAddressToUse?: string;
  vmPort?: number;
  vmSize?: string;
  diskLocation?: string;
  imageName?: string;
  surrealGameName?: string;
  vmUserName?: string | null;
  vmUserPassword?: string | null;
  sshUserName?: string | null;
  sshPassword?: string | null;
  sshPublicKey?: string | null;
  preProcessingCommands?: string | null;
  postProcessingCommands?: string | null;
  signalingServerIP?: string;
  signalingServerPort?: string;
  publicIP?: string | null;
  privateIP?: string;
  dnsNameURL?: string | null;
  streamerConnected?: boolean;
  userConnected?: boolean;
  hasError?: boolean;
  connectedClientIP?: string | null;
  connectedUserId?: string | null;
  currentConnectionStarted?: string | null;
  lastConnectionEnded?: string | null;
  lastContactFromStreamer?: string | null;
  isTemplate?: boolean;
}

export const defaultValue: Readonly<IInstance> = {
  streamerConnected: false,
  userConnected: false,
  hasError: false,
  isTemplate: false,
};
