import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';
import { getEntities as getSurrealSubExperiences } from 'app/entities/surreal-sub-experience/surreal-sub-experience.reducer';
import { ISurrealLevel } from 'app/shared/model/surreal-level.model';
import { getEntities as getSurrealLevels } from 'app/entities/surreal-level/surreal-level.reducer';
import { ISurrealUser } from 'app/shared/model/surreal-user.model';
import { getEntities as getSurrealUsers } from 'app/entities/surreal-user/surreal-user.reducer';
import { IAgenda } from 'app/shared/model/agenda.model';
import { getEntities as getAgenda } from 'app/entities/agenda/agenda.reducer';
import { IAppointment } from 'app/shared/model/appointment.model';
import { Frequency } from 'app/shared/model/enumerations/frequency.model';
import { getEntity, updateEntity, createEntity, reset } from './appointment.reducer';

export const AppointmentUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const surrealSubExperiences = useAppSelector(state => state.surrealSubExperience.entities);
  const surrealLevels = useAppSelector(state => state.surrealLevel.entities);
  const surrealUsers = useAppSelector(state => state.surrealUser.entities);
  const agenda = useAppSelector(state => state.agenda.entities);
  const appointmentEntity = useAppSelector(state => state.appointment.entity);
  const loading = useAppSelector(state => state.appointment.loading);
  const updating = useAppSelector(state => state.appointment.updating);
  const updateSuccess = useAppSelector(state => state.appointment.updateSuccess);
  const frequencyValues = Object.keys(Frequency);

  const handleClose = () => {
    navigate('/appointment');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getSurrealSubExperiences({}));
    dispatch(getSurrealLevels({}));
    dispatch(getSurrealUsers({}));
    dispatch(getAgenda({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.startDateTime = convertDateTimeToServer(values.startDateTime);
    values.endDateTime = convertDateTimeToServer(values.endDateTime);

    const entity = {
      ...appointmentEntity,
      ...values,
      surrealSubExperience: surrealSubExperiences.find(it => it.id.toString() === values.surrealSubExperience.toString()),
      surrealLevel: surrealLevels.find(it => it.id.toString() === values.surrealLevel.toString()),
      surrealUser: surrealUsers.find(it => it.id.toString() === values.surrealUser.toString()),
      agenda: agenda.find(it => it.id.toString() === values.agenda.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          startDateTime: displayDefaultDateTime(),
          endDateTime: displayDefaultDateTime(),
        }
      : {
          frequency: 'HOURLY',
          ...appointmentEntity,
          startDateTime: convertDateTimeFromServer(appointmentEntity.startDateTime),
          endDateTime: convertDateTimeFromServer(appointmentEntity.endDateTime),
          surrealSubExperience: appointmentEntity?.surrealSubExperience?.id,
          surrealLevel: appointmentEntity?.surrealLevel?.id,
          surrealUser: appointmentEntity?.surrealUser?.id,
          agenda: appointmentEntity?.agenda?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.appointment.home.createOrEditLabel" data-cy="AppointmentCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.appointment.home.createOrEditLabel">Create or edit a Appointment</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="appointment-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.appointment.title')}
                id="appointment-title"
                name="title"
                data-cy="title"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.appointment.location')}
                id="appointment-location"
                name="location"
                data-cy="location"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.appointment.surrealExperienceGroupName')}
                id="appointment-surrealExperienceGroupName"
                name="surrealExperienceGroupName"
                data-cy="surrealExperienceGroupName"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.appointment.inviterPerson')}
                id="appointment-inviterPerson"
                name="inviterPerson"
                data-cy="inviterPerson"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.appointment.invitedPerson')}
                id="appointment-invitedPerson"
                name="invitedPerson"
                data-cy="invitedPerson"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.appointment.startDateTime')}
                id="appointment-startDateTime"
                name="startDateTime"
                data-cy="startDateTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.appointment.endDateTime')}
                id="appointment-endDateTime"
                name="endDateTime"
                data-cy="endDateTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('surrealManagerApp.appointment.recurring')}
                id="appointment-recurring"
                name="recurring"
                data-cy="recurring"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('surrealManagerApp.appointment.frequency')}
                id="appointment-frequency"
                name="frequency"
                data-cy="frequency"
                type="select"
              >
                {frequencyValues.map(frequency => (
                  <option value={frequency} key={frequency}>
                    {translate('surrealManagerApp.Frequency.' + frequency)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                id="appointment-surrealSubExperience"
                name="surrealSubExperience"
                data-cy="surrealSubExperience"
                label={translate('surrealManagerApp.appointment.surrealSubExperience')}
                type="select"
              >
                <option value="" key="0" />
                {surrealSubExperiences
                  ? surrealSubExperiences.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="appointment-surrealLevel"
                name="surrealLevel"
                data-cy="surrealLevel"
                label={translate('surrealManagerApp.appointment.surrealLevel')}
                type="select"
              >
                <option value="" key="0" />
                {surrealLevels
                  ? surrealLevels.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="appointment-surrealUser"
                name="surrealUser"
                data-cy="surrealUser"
                label={translate('surrealManagerApp.appointment.surrealUser')}
                type="select"
              >
                <option value="" key="0" />
                {surrealUsers
                  ? surrealUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="appointment-agenda"
                name="agenda"
                data-cy="agenda"
                label={translate('surrealManagerApp.appointment.agenda')}
                type="select"
              >
                <option value="" key="0" />
                {agenda
                  ? agenda.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/appointment" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppointmentUpdate;
