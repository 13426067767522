import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './event-integration.reducer';

export const EventIntegrationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const eventIntegrationEntity = useAppSelector(state => state.eventIntegration.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="eventIntegrationDetailsHeading">
          <Translate contentKey="surrealManagerApp.eventIntegration.detail.title">EventIntegration</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{eventIntegrationEntity.id}</dd>
          <dt>
            <span id="integrationName">
              <Translate contentKey="surrealManagerApp.eventIntegration.integrationName">Integration Name</Translate>
            </span>
          </dt>
          <dd>{eventIntegrationEntity.integrationName}</dd>
          <dt>
            <span id="accessToken1">
              <Translate contentKey="surrealManagerApp.eventIntegration.accessToken1">Access Token 1</Translate>
            </span>
          </dt>
          <dd>{eventIntegrationEntity.accessToken1}</dd>
          <dt>
            <span id="accessToken2">
              <Translate contentKey="surrealManagerApp.eventIntegration.accessToken2">Access Token 2</Translate>
            </span>
          </dt>
          <dd>{eventIntegrationEntity.accessToken2}</dd>
          <dt>
            <span id="secret">
              <Translate contentKey="surrealManagerApp.eventIntegration.secret">Secret</Translate>
            </span>
          </dt>
          <dd>{eventIntegrationEntity.secret}</dd>
          <dt>
            <span id="userId">
              <Translate contentKey="surrealManagerApp.eventIntegration.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{eventIntegrationEntity.userId}</dd>
          <dt>
            <span id="locationUrl">
              <Translate contentKey="surrealManagerApp.eventIntegration.locationUrl">Location Url</Translate>
            </span>
          </dt>
          <dd>{eventIntegrationEntity.locationUrl}</dd>
        </dl>
        <Button tag={Link} to="/event-integration" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/event-integration/${eventIntegrationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EventIntegrationDetail;
