import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ISale } from 'app/shared/model/sale.model';
import { getEntities as getSales } from 'app/entities/sale/sale.reducer';
import { ISurrealSubExperience } from 'app/shared/model/surreal-sub-experience.model';
import { getEntities as getSurrealSubExperiences } from 'app/entities/surreal-sub-experience/surreal-sub-experience.reducer';
import { IItemToSell } from 'app/shared/model/item-to-sell.model';
import { getEntity, updateEntity, createEntity, reset } from './item-to-sell.reducer';

export const ItemToSellUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const sales = useAppSelector(state => state.sale.entities);
  const surrealSubExperiences = useAppSelector(state => state.surrealSubExperience.entities);
  const itemToSellEntity = useAppSelector(state => state.itemToSell.entity);
  const loading = useAppSelector(state => state.itemToSell.loading);
  const updating = useAppSelector(state => state.itemToSell.updating);
  const updateSuccess = useAppSelector(state => state.itemToSell.updateSuccess);

  const handleClose = () => {
    navigate('/item-to-sell');
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }

    dispatch(getSales({}));
    dispatch(getSurrealSubExperiences({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...itemToSellEntity,
      ...values,
      sale: sales.find(it => it.id.toString() === values.sale.toString()),
      surrealSubExperience: surrealSubExperiences.find(it => it.id.toString() === values.surrealSubExperience.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...itemToSellEntity,
          sale: itemToSellEntity?.sale?.id,
          surrealSubExperience: itemToSellEntity?.surrealSubExperience?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="surrealManagerApp.itemToSell.home.createOrEditLabel" data-cy="ItemToSellCreateUpdateHeading">
            <Translate contentKey="surrealManagerApp.itemToSell.home.createOrEditLabel">Create or edit a ItemToSell</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="item-to-sell-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('surrealManagerApp.itemToSell.itemName')}
                id="item-to-sell-itemName"
                name="itemName"
                data-cy="itemName"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.itemToSell.itemPrice')}
                id="item-to-sell-itemPrice"
                name="itemPrice"
                data-cy="itemPrice"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.itemToSell.itemSalePrice')}
                id="item-to-sell-itemSalePrice"
                name="itemSalePrice"
                data-cy="itemSalePrice"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.itemToSell.itemStoreLink')}
                id="item-to-sell-itemStoreLink"
                name="itemStoreLink"
                data-cy="itemStoreLink"
                type="text"
              />
              <ValidatedField
                label={translate('surrealManagerApp.itemToSell.itemActive')}
                id="item-to-sell-itemActive"
                name="itemActive"
                data-cy="itemActive"
                check
                type="checkbox"
              />
              <ValidatedField
                id="item-to-sell-sale"
                name="sale"
                data-cy="sale"
                label={translate('surrealManagerApp.itemToSell.sale')}
                type="select"
              >
                <option value="" key="0" />
                {sales
                  ? sales.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="item-to-sell-surrealSubExperience"
                name="surrealSubExperience"
                data-cy="surrealSubExperience"
                label={translate('surrealManagerApp.itemToSell.surrealSubExperience')}
                type="select"
              >
                <option value="" key="0" />
                {surrealSubExperiences
                  ? surrealSubExperiences.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/item-to-sell" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ItemToSellUpdate;
