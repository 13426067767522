import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MiniGame from './mini-game';
import MiniGameDetail from './mini-game-detail';
import MiniGameUpdate from './mini-game-update';
import MiniGameDeleteDialog from './mini-game-delete-dialog';

const MiniGameRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MiniGame />} />
    <Route path="new" element={<MiniGameUpdate />} />
    <Route path=":id">
      <Route index element={<MiniGameDetail />} />
      <Route path="edit" element={<MiniGameUpdate />} />
      <Route path="delete" element={<MiniGameDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MiniGameRoutes;
