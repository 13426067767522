import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './event-log.reducer';

export const EventLogDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const eventLogEntity = useAppSelector(state => state.eventLog.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="eventLogDetailsHeading">
          <Translate contentKey="surrealManagerApp.eventLog.detail.title">EventLog</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{eventLogEntity.id}</dd>
          <dt>
            <span id="sessionId">
              <Translate contentKey="surrealManagerApp.eventLog.sessionId">Session Id</Translate>
            </span>
          </dt>
          <dd>{eventLogEntity.sessionId}</dd>
          <dt>
            <span id="userId">
              <Translate contentKey="surrealManagerApp.eventLog.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{eventLogEntity.userId}</dd>
          <dt>
            <span id="beforeState">
              <Translate contentKey="surrealManagerApp.eventLog.beforeState">Before State</Translate>
            </span>
          </dt>
          <dd>{eventLogEntity.beforeState}</dd>
          <dt>
            <span id="afterState">
              <Translate contentKey="surrealManagerApp.eventLog.afterState">After State</Translate>
            </span>
          </dt>
          <dd>{eventLogEntity.afterState}</dd>
          <dt>
            <span id="eventSpecificData">
              <Translate contentKey="surrealManagerApp.eventLog.eventSpecificData">Event Specific Data</Translate>
            </span>
          </dt>
          <dd>{eventLogEntity.eventSpecificData}</dd>
          <dt>
            <span id="eventTimeStamp">
              <Translate contentKey="surrealManagerApp.eventLog.eventTimeStamp">Event Time Stamp</Translate>
            </span>
          </dt>
          <dd>
            {eventLogEntity.eventTimeStamp ? (
              <TextFormat value={eventLogEntity.eventTimeStamp} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="surrealManagerApp.eventLog.eventType">Event Type</Translate>
          </dt>
          <dd>{eventLogEntity.eventType ? eventLogEntity.eventType.id : ''}</dd>
          <dt>
            <Translate contentKey="surrealManagerApp.eventLog.surrealMainExperience">Surreal Main Experience</Translate>
          </dt>
          <dd>{eventLogEntity.surrealMainExperience ? eventLogEntity.surrealMainExperience.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/event-log" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/event-log/${eventLogEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EventLogDetail;
