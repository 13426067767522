import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SurrealFile from './surreal-file';
import SurrealFileDetail from './surreal-file-detail';
import SurrealFileUpdate from './surreal-file-update';
import SurrealFileDeleteDialog from './surreal-file-delete-dialog';

const SurrealFileRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SurrealFile />} />
    <Route path="new" element={<SurrealFileUpdate />} />
    <Route path=":id">
      <Route index element={<SurrealFileDetail />} />
      <Route path="edit" element={<SurrealFileUpdate />} />
      <Route path="delete" element={<SurrealFileDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SurrealFileRoutes;
